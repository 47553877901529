import { mdiAccountOutline, mdiHomeOutline } from '@mdi/js'

/* eslint-disable */
// prettier-ignore
export default [
  { header: 'Pages' },

  // dashboard
  { title: 'Overview', to: { name: 'dashboard-overview' }, icon: mdiHomeOutline },

  { title: 'Snowflake Users', to: { name: 'snowflake-users-list' }, icon: mdiAccountOutline },
  // { title: 'User View', to: { name: 'apps-user-view', params: { id: 21 } }, icon: mdiAccountOutline },

  // Contacts
  { header: 'Contacts' },
  { avatar: require('@/assets/images/avatars/6.png'), title: 'Rena Brant', email: 'nephrod@preany.co.uk', time: '21/05/2019' },
  { avatar: require('@/assets/images/avatars/4.png'), title: 'Mariano Packard', email: 'seek@sparaxis.org', time: '14/01/2018' },
  { avatar: require('@/assets/images/avatars/3.png'), title: 'Risa Montufar', email: 'vagary@unblist.org', time: '10/08/2019' },
  { avatar: require('@/assets/images/avatars/7.png'), title: 'Maragaret Cimo', email: 'designed@insanely.net', time: '01/12/2019' },
  { avatar: require('@/assets/images/avatars/8.png'), title: 'Jona Prattis', email: 'unwieldable@unblist.org', time: '21/05/2019' },
  { avatar: require('@/assets/images/avatars/5.png'), title: 'Edmond Chicon', email: 'museist@anaphyte.co.uk', time: '15/11/2019' },
  { avatar: require('@/assets/images/avatars/6.png'), title: 'Abbey Darden', email: 'astema@defectively.co.uk', time: '07/05/2019' },
  { avatar: require('@/assets/images/avatars/1.png'), title: 'Seema Moallankamp', email: 'fernando@storkish.co.uk', time: '13/08/2017' },
  { avatar: require('@/assets/images/avatars/3.png'), title: 'Charleen Warmington', email: 'furphy@cannibal.net', time: '11/08/1891' },
  { avatar: require('@/assets/images/avatars/5.png'), title: 'Geri Linch', email: 'insignia@markab.org', time: '18/01/2015' },
  { avatar: require('@/assets/images/avatars/2.png'), title: 'Shellie Muster', email: 'maxillary@equalize.co.uk', time: '26/07/2019' },
  { avatar: require('@/assets/images/avatars/7.png'), title: 'Jesenia Vanbramer', email: 'hypotony@phonetist.net', time: '12/09/2017' },
  { avatar: require('@/assets/images/avatars/1.png'), title: 'Mardell Channey', email: 'peseta@myrica.com', time: '11/11/2019' },
]
