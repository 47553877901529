<template>
  <v-container
    class="mt-5"
    fill-height
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        md="10"
        sm="12"
      >
        <v-card>
          <v-row justify="center">
            <v-img
              :src="require(`@/assets/images/logos/SnowFlowLogo_Large.png`)"
              max-height="200"
              max-width="200"
              alt="logo"
              contain
              class="ma-5"
            ></v-img>
          </v-row>

          <v-card-title
            style="padding-top:40px"
            class="justify-center"
          >
            <v-img
              :src="serverConfig.logo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              eager
              class="app-logo me-3"
            ></v-img>
            <h3>
              WELCOME TO {{ serverConfig.name.toUpperCase() }}
            </h3>
          </v-card-title>

          <v-card-text>
            <p class="text--primary text-base text-center">
              Let's get you all setup. It should only take a few minutes.
            </p>
          </v-card-text>
          <!-- Sections consist of: Verifying Temp Credentials, Setting New Password, oAuth Creation -->
          <!-- Enter Temporary Credentials To Set Password -->
          <verify-temp-password
            v-if="accountSetupType === 'verifyTempPassword'"
            :account="account"
            :username="username"
            @verificationSuccessful="tempPasswordVerificationSuccessful = true"
            @setEmail="setEmail"
          ></verify-temp-password>
          <!-- Create New App Password -->
          <set-new-app-password
            v-if="accountSetupType === 'setNewAppPassword'"
            :account="account"
            :email="email"
          ></set-new-app-password>
          <!-- Setup Snowflake OAuth -->
          <snowflake-o-auth-setup
            v-if="accountSetupType === 'oauthSetup'"
          ></snowflake-o-auth-setup>
          <new-user-setup
            v-if="accountSetupType === 'userSetup'"
          ></new-user-setup>
          <user-create-from-oauth
            v-if="accountSetupType === 'createUserFromOauth'"
          >
          </user-create-from-oauth>
          <system-setup-for-snowflake v-if="accountSetupType === 'systemAccountSetup'">
          </system-setup-for-snowflake>
          <o-auth-setup-test v-if="accountSetupType === 'oauthSetupTest'">
          </o-auth-setup-test>

          <!-- Add Snowflake Credentials -->
          <!-- <template v-if="!verificationSuccessful">
          </template> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// eslint-disable-next-line object-curly-newline
/* eslint-disable prefer-destructuring */
import { useRouter } from '@core/utils'
import serverConfig from '@serverConfig'
import { computed, ref, watchEffect } from '@vue/composition-api'
import SystemSetupForSnowflake from './account-setup/SystemSetupForSnowflake.vue'
import SetNewAppPassword from './SetNewAppPassword.vue'
import OAuthSetupTest from './snowflake-oauth-setup/OAuthSetupTest.vue'
import SnowflakeOAuthSetup from './snowflake-oauth-setup/SnowflakeOAuthSetup.vue'
import UserCreateFromOauth from './user-setup/UserCreateFromOauth.vue'
import NewUserSetup from './user-setup/UserSetup.vue'
import VerifyTempPassword from './VerifyTempPassword.vue'

export default {
  components: {
    UserCreateFromOauth,
    OAuthSetupTest,
    SnowflakeOAuthSetup,
    VerifyTempPassword,
    SetNewAppPassword,
    NewUserSetup,
    SystemSetupForSnowflake,
  },
  setup() {
    const { route } = useRouter()
    const account = ref(null)
    const username = ref(null)
    const tempPasswordVerificationSuccessful = ref(false)
    const email = ref(null)
    const setEmail = emmitedEmail => {
      email.value = emmitedEmail
      localStorage.setItem('accountId', account.value.id || account.value)
      localStorage.setItem('accountEmail', email.value)
    }

    watchEffect(() => {
      const url = route.value.params.id.split(':')
      account.value = url[0]
      username.value = url[1] ? url[1] : null
    })

    const accountSetupType = computed(() => {
      const params = route.value.params.id.split(':')[0]
      if (params === 'system-account-setup') return 'systemAccountSetup'
      if (params === 'user-setup') return 'userSetup'
      if (params === 'create-user-from-oauth') return 'createUserFromOauth'
      if (params === 'oauth-setup-test') return 'oauthSetupTest'
      if (params === 'oauth-setup') return 'oauthSetup'
      if (tempPasswordVerificationSuccessful.value) return 'setNewAppPassword'

      return 'verifyTempPassword'
    })

    return {
      username,
      accountSetupType,
      setEmail,
      tempPasswordVerificationSuccessful,
      account,
      email,
      UserCreateFromOauth,
      VerifyTempPassword,
      SetNewAppPassword,
      NewUserSetup,
      serverConfig,
      OAuthSetupTest,
      SnowflakeOAuthSetup,
      SystemSetupForSnowflake,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
