<template>
  <v-card>
    <v-card-title class="headline">
      Are You Sure?
    </v-card-title>
    <v-card-text>If you decline to add a Snowflake account to {{ serverConfig.name }} your abilities may be very limited. Would you like to continue without adding a snowflake account? </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        @click="declined"
      >
        Yes
      </v-btn>
      <v-btn
        color="success"
        @click="closeDialog"
      >
        No
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import serverConfig from '@serverConfig'

export default {
  setup(_, { emit }) {
    const closeDialog = () => {
      emit('closeDialog')
    }
    const declined = () => {
      emit('declined')
    }

    return {
      declined,
      closeDialog,
      serverConfig,
    }
  },
}
</script>
