// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB0ByyzaYenAll9kU5aSzDrdImxt3bYPSw',
  authDomain: 'snowflow-lendio.firebaseapp.com',
  projectId: 'snowflow-lendio',
  storageBucket: 'snowflow-lendio.appspot.com',
  messagingSenderId: '300281031040',
  appId: '1:300281031040:web:1d4c1d610aa1aa71da7965',
}

// eslint-disable-next-line import/prefer-default-export
export { firebaseConfig }
