<!-- Create New App Password -->
<template>
  <div>
    <v-card-actions>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row justify="space-around">
          <v-col
            sm="12"
          >
            <v-alert
              border="left"
              colored-border
              text
              color="primary"
            >
              <strong>Nicely Done. Now let's create a new {{ serverConfig.name }} password.</strong>
            </v-alert>
          </v-col>

          <v-col
            sm="12"
            md="5"
          >
            <v-text-field
              v-model="newAppPassword1"
              type="password"
              label="New Password"
              outlined
              :rules="[required,passwordValidator]"
              @focus="passwordNotMatchedError = false"
            >
            </v-text-field>
          </v-col>
          <v-col
            sm="12"
            md="5"
          >
            <v-text-field
              v-model="newAppPassword2"
              type="password"
              label="Verify Password"
              outlined
              @focus="passwordNotMatchedError = false"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-actions>
    <v-alert
      v-if="passwordNotMatchedError"
      type="error"
      text
    >
      The Passwords Do Not Match. Please Try Again.
    </v-alert>
    <v-card-actions class="primary pa-0">
      <v-btn
        :disabled="!valid || !newAppPassword2"
        color="primary"
        block
        dark
        large
        @click="verifyNewPassword"
      >
        SUBMIT
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { deleteDoc } from '@/firestore'
import { createAppUser } from '@/functions/authentication'
import { useRouter } from '@core/utils'
import { passwordValidator, required } from '@core/utils/validation'
import serverConfig from '@serverConfig'
import { ref } from '@vue/composition-api'

export default {
  props: {
    account: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { router } = useRouter()

    // Setting New App Password
    const valid = ref(true)
    const passwordNotMatchedError = ref(false)
    const newAppPassword1 = ref(null)
    const newAppPassword2 = ref(null)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const verifyNewPassword = async () => {
      if (newAppPassword1.value !== newAppPassword2.value) {
        passwordNotMatchedError.value = true
      } else {
        const newAppResponse = await createAppUser({
          email: props.email,
          password: newAppPassword1.value,
          accountId: props.account,
        })
        if (newAppResponse === 'Success') {
          // Router to /user-setup
          // Remove waitingOnAccountCreation user record

          await deleteDoc({
            account: { id: props.account },
            collection: 'users',
            docId: 'waitingOnAccountCreation',
            subCollection: 'waitingOnAccountCreation',
            subCollectionDocId: props.email,
          })
          router.push('/account-setup/user-setup/')
        }
      }
    }

    return {
      serverConfig,
      valid,
      form,
      passwordNotMatchedError,
      newAppPassword1,
      newAppPassword2,
      validate,
      verifyNewPassword,
      passwordValidator,
      required,
    }
  },
}
</script>
