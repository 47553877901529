<template>
  <div>
    <v-row
      justify="center"
    >
      <h3>Testing Snowflake OAuth Authentication</h3>
    </v-row>
    <v-row
      justify="center"
      class="mt-9"
    >
      <h5>Any Oauth Errors Returning from Snowflake should report here or on the Snowflake page.</h5>
    </v-row>
    <v-row
      justify="center"
      class="mt-9"
    >
      <v-alert
        v-if="snowflakeOathError"
        type="error"
        text
      >
        {{ snowflakeOathError }}
      </v-alert>
    </v-row>
    <v-row
      justify="center"
      class="mt-9 pb-9"
    >
      <v-btn
        color="info"
        @click="snowflakeOath"
      >
        Try Snowflake Authentication Again
      </v-btn>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
/* eslint-disable operator-linebreak */
import { getOathCode } from '@/functions/snowflakeOauth'
import serverConfig from '@serverConfig'
import { onMounted, ref } from '@vue/composition-api'

export default {
  setup() {
    const snowflakeAccount = localStorage.getItem('snowflakeAccountId')
    const snowflakeOathError = ref(null)

    const snowflakeOath = async () => {
      snowflakeOathError.value = null
      console.log('Snowflake Auth Initiated....')
      try {
        const oAuthCode = await getOathCode(snowflakeAccount)
        if (oAuthCode === 'No Account') {
          snowflakeOathError.value = `This snowflake instance is not part of a ${serverConfig.name} Account. You must setup a ${serverConfig.name} account first.`
        }
        if (oAuthCode === 'No Snowflake Oauth') {
          snowflakeOathError.value = 'Your Snowflake account is not setup for oAuth Authentication'
        }
      } catch (err) {
        console.log(err)
        snowflakeOathError.value = 'Your Snowflake Account or Username is incorrect.  Also your default role cannot be ACCOUNTADMIN or SECURITYADMIN.'
      }
    }

    onMounted(() => {
      snowflakeOath()
    })

    return { snowflakeOath, snowflakeOathError }
  },
}
</script>
