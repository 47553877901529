// Import the functions you need from the SDKs you need
import serverConfig from '@serverConfig'
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions'
import { firebaseConfig } from '../privateCred'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)
const db = getFirestore()
const functions = getFunctions(firebaseApp)
const auth = getAuth()

// To Run Local Function Emulation -> firebase emulators:start --only functions
if (serverConfig.functionEnvironment === 'local') {
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

// eslint-disable-next-line object-curly-newline
export { firebaseApp, db, functions, analytics, auth, httpsCallable }
