import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import VueTree from '@ssthouse/vue-tree-chart'
import moment from 'moment-timezone'
import Vue from 'vue'
import VueMoment from 'vue-moment'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(VueMoment, {
  moment,
})

// Global Components
Vue.component('vue-tree', VueTree)
Vue.component('help-badge', () => import('./components/global/HelpBadge.vue'))
Vue.component('help-dialog', () => import('./components/global/HelpDialog.vue'))

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
