<!-- Enter Temporary Credentials For Access -->
<template>
  <div>
    <v-card-actions>
      <v-row justify="space-around">
        <v-col
          sm="12"
        >
          <v-alert
            border="left"
            colored-border
            text
            color="primary"
          >
            <strong>You should have received a temporary password in your email. Let's enter it now.</strong>
          </v-alert>
        </v-col>
        <v-col
          sm="12"
          md="5"
        >
          <v-text-field
            v-model="email"
            label="Enter Email"
            outlined
            @focus="credentialsFailed = false"
          >
          </v-text-field>
        </v-col>
        <v-col
          sm="12"
          md="5"
        >
          <v-text-field
            v-model="password"
            label="Enter Temporary Password"
            outlined
            @focus="credentialsFailed = false"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-alert
      v-if="credentialsFailed"
      type="error"
      text
    >
      The Email/Password You Entered Is Not Correct. Please Try Again.
    </v-alert>
    <v-card-actions class="primary pa-0">
      <v-btn
        :loading="verificationLoading"
        color="primary"
        block
        dark
        large
        @click="verifyTempPassword"
      >
        SUBMIT
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { functions, httpsCallable } from '../../firebaseApp'

export default {
  props: {
    account: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    // Temp Password Verification
    const email = ref(null)
    const password = ref(null)
    const credentialsFailed = ref(false)
    const verificationLoading = ref(false)
    const verifyTempPassword = async () => {
      credentialsFailed.value = false
      verificationLoading.value = true
      if (!email.value || !password.value) {
        credentialsFailed.value = true
      } else {
        const { account } = props
        const payload = { accountId: account, email: email.value, password: password.value }
        const validateTempPassword = httpsCallable(functions, 'validateTempPassword')
        const response = await validateTempPassword(payload)
        if (response.data === 'SUCCESS') {
          emit('verificationSuccessful')
          emit('setEmail', email.value)
        } else {
          credentialsFailed.value = true
        }
      }
      verificationLoading.value = false
    }

    onMounted(() => {
      // If Username (Email) Exists in URL
      if (props.username) email.value = props.username
    })

    return {
      email,
      password,
      credentialsFailed,
      verificationLoading,
      verifyTempPassword,
    }
  },
}
</script>
