import { db } from '@/firebaseApp'
import { firestoreTimestamps } from '@/firestore'
import { cloudFunction } from '@/functions'
// eslint-disable-next-line object-curly-newline
import { parseSnowBindingEvents, setAfterJobSchedule, snowBindingSchedule } from '@/functions/snowBindings'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import moment from 'moment-timezone'

export default {
  namespaced: true,
  state: {
    snowBindingCreation: {}, // Used To Save SnowBinding Creation Objects
    snowBindingCron: { lastCronValue: null, lastAfterJob: null, cron: null, scheduleCreation: null }, // Used to Save Cloud Scheduling Data for SnowBindings - May result in "afterJob" in which other functions will be created
    snowBindingsInterval: 'Today',
    snowBindingsStartAt: moment().subtract(11, 'hour').toDate(),
    snowBindingsEndAt: moment().toDate(),
    snowBindingTemplates: null,
    snowBindingTasks: null,
    snowBindingConnectors: null,
    snowBindingEvents: null,
  },
  mutations: {
    setSnowBindingCreation(state, payload) {
      state.snowBindingCreation = { ...state.snowBindingCreation, ...payload }
    },
    setSnowBindingCron(state, payload) {
      if (!payload) state.snowBindingCron = { lastCronValue: null, lastAfterJob: null, cron: null, scheduleCreation: null }
      else state.snowBindingCron = { ...state.snowBindingCron, ...payload }
    },
    setSnowBindingsStartAt(state, payload) {
      state.snowBindingsStartAt = payload
    },
    setSnowBindingsEndAt(state, payload) {
      state.snowBindingsEndAt = payload
    },
    setSnowBindingTemplates(state, payload) {
      state.snowBindingTemplates = payload
    },
    setSnowBindingTasks(state, payload) {
      state.snowBindingTasks = payload
    },
    setSnowBindingConnectors(state, payload) {
      state.snowBindingConnectors = payload
    },
    setSnowBindingEvents(state, payload) {
      state.snowBindingEvents = payload
    },
  },
  actions: {
    // ————————————————————————————————————————————————————————————
    //* ——— Scheduling Data Is Primary Managed From The Store
    // ————————————————————————————————————————————————————————————
    async snowBindingScheduleExecution({ commit, state, rootState }, payload) {
      try {
        const { task, connector } = payload
        const account = rootState.accountProfile

        const { lastCronValue } = state.snowBindingCron
        const lastAfterJobId = lastCronValue === 'afterJob' && state.snowBindingCron.lastAfterJob ? state.snowBindingCron.lastAfterJob : null
        const newAfterJobId = state.snowBindingCron.cron === 'afterJob' && state.snowBindingCron.scheduleCreation.afterJob ? state.snowBindingCron.scheduleCreation.afterJob : null
        const lastCloudCronValue = lastCronValue && lastCronValue !== 'afterJob' ? lastCronValue : null
        const cloudCronValue = state.snowBindingCron.cron && state.snowBindingCron.cron !== 'afterJob' ? state.snowBindingCron.cron : null
        const schedulePayload = { account, bindingId: task.id, connector, task, cron: state.snowBindingCron.cron }
        const { scheduleCreation } = state.snowBindingCron
        if (state.snowBindingCron?.scheduleCreation?.timeZone) schedulePayload.timeZone = state.snowBindingCron.scheduleCreation.timeZone

        // Build CRON Job on Google Cloud Scheduler
        // Will Create, Update, Delete based on Cron value
        if (lastCloudCronValue || cloudCronValue) {
          await snowBindingSchedule(schedulePayload)
        }

        // If AfterJobs are the Same Do Nothing
        if (lastAfterJobId || newAfterJobId || cloudCronValue || lastCloudCronValue) {
          if (!lastCloudCronValue && !cloudCronValue && (lastAfterJobId === newAfterJobId)) return

          // Set/Remove AfterJobs Array on Both Target Task and Parent Task
          const lastAfterJobTask = lastAfterJobId ? state.snowBindingTasks.filter(f => f.id === lastAfterJobId)[0] : null
          const newAfterJobTask = newAfterJobId ? state.snowBindingTasks.filter(f => f.id === newAfterJobId)[0] : null

          // Update Task Binding Record and afterJob Schedule Entries
          await setAfterJobSchedule({ task, lastAfterJobTask, newAfterJobTask, newCloudSchedule: scheduleCreation })
          commit('setSnowBindingCron', { lastAfterJob: newAfterJobId, lastCronValue: state.snowBindingCron.cron })
        }

        // IF Do Nothing On Cloud
        if ((!lastCloudCronValue && !cloudCronValue) || (lastCloudCronValue === cloudCronValue)) return

        commit('setSnowBindingCron', { lastCronValue: state.snowBindingCron.cron })
      } catch (error) {
        const errorMessage = `Failed To Create SnowBinding Schedules. ERROR: ${error}`
        console.trace('\u001b[1;31m', errorMessage)
        throw errorMessage
      }
    },

    // Get Sync Start Time
    // ///////////////////
    snowBindingsStartAt({ state, commit }) {
      let startAt = null
      if (state.snowBindingsInterval === 'Today') startAt = moment().subtract(11, 'hour').toDate()
      else if (state.snowBindingsInterval === 'Last Hour') startAt = moment().subtract(1, 'hour').toDate()
      else startAt = null
      commit('setSnowBindingsStartAt', startAt)
    },

    // Get Sync End Time
    // ///////////////////
    snowBindingsEndAt({ state, commit }) {
      let endAt = null
      if (['Today', 'Last Hour'].includes(state.snowBindingsInterval)) endAt = moment().toDate()
      else endAt = null
      commit('setSnowBindingsEndAt', endAt)
    },

    // Sync Snow Binding Templates
    // /////////////////////
    async snowBindingTemplates({ commit }) {
      const connectorDefinitions = await cloudFunction({ functionName: 'snowBindingConnectionDefinitions' })
      commit('setSnowBindingTemplates', connectorDefinitions.data)
    },

    // Sync Snow Binding Tasks -- Syncs Entire Collection
    // ///////////////////////
    async syncSnowBindingTasks({ commit, rootState }, payload) {
      console.log('SYNCING: Getting SnowBinding Tasks...')
      const { showInactive } = payload
      const account = rootState.accountProfile
      let path = null
      if (showInactive) {
        path = query(collection(db, `snowBindings/accounts/${account.id}/connectors/bindings`))
      } else {
        path = query(collection(db, `snowBindings/accounts/${account.id}/connectors/bindings`), where('isDeleted', '==', false))
      }
      onSnapshot(path, collectionSnapshot => {
        let connectors = []
        collectionSnapshot.forEach(document => {
          connectors.push(document.data())
        })
        connectors = firestoreTimestamps(connectors)
        commit('setSnowBindingTasks', connectors)
        commit('syncList', 'snowBindings/setSnowBindingTasks', { root: true })
      })
    },

    // Sync Snow Binding Connections
    // /////////////////////
    async syncSnowBindingConnectors({ commit, rootState }) {
      console.log('SYNCING: Getting SnowBinding Connectors...')
      const account = rootState.accountProfile

      const path = query(collection(db, `snowBindings/accounts/${account.id}/connectors/auth`), where('isDeleted', '==', false))
      onSnapshot(path, collectionSnapshot => {
        let connectors = []
        collectionSnapshot.forEach(document => {
          connectors.push(document.data())
        })
        connectors = firestoreTimestamps(connectors)
        commit('setSnowBindingConnectors', connectors)
        commit('syncList', 'snowBindings/setSnowBindingConnectors', { root: true })

        // if (localStorage.getItem('currentPath').includes('snow-bindings')) {
        //   dispatch('syncSnowBindingTasks')
        // }
      })
    },

    // Sync Snow Binding Events
    // ////////////////////////
    async syncSnowBindingEvents({ state, commit, rootState }) {
      console.log('SYNCING: Getting SnowBinding Events...')
      const account = rootState.accountProfile

      // const minDate = moment().subtract(60, 'days').toDate()
      const path = query(collection(db, `snowBindingEvents/accounts/${account.id}`), where('lastModifiedAt', '>=', state.snowBindingsStartAt))
      onSnapshot(path, collectionSnapshot => {
        const snowBindingEvents = []
        collectionSnapshot.forEach(document => {
          const [timestampFix] = firestoreTimestamps([document.data()])
          const parsedEvents = parseSnowBindingEvents(timestampFix)
          parsedEvents.runs.forEach(run => {
            if (run.executionStartTime >= state.snowBindingsStartAt) {
              snowBindingEvents.push({ bindingId: parsedEvents.bindingId, ...run })
            }
          })
        })

        commit('setSnowBindingEvents', snowBindingEvents)
        commit('syncList', 'snowBindings/setSnowBindingConnectors', { root: true })
      })
    },
  },
}
