/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
// import { addDoc, collection } from 'firebase/firestore'
import { db, functions, httpsCallable } from '@/firebaseApp'
import { firestoreTimestamps, updateDoc, writeDoc } from '@/firestore'
import { encryptData } from '@/functions'
import serverConfig from '@serverConfig'
import { collection, onSnapshot, query } from 'firebase/firestore'

export default {
  namespaced: true,
  state: {
    appRoles: [],
    appUsers: [],
    account: null,
  },
  mutations: {
    setAppRoles(state, payload) {
      state.appRoles = payload
    },
    setAppUsers(state, payload) {
      if (payload.type === 'activeUsers') {
        const users = state.appUsers.filter(f => !f.id)
        state.appUsers = payload.users.concat(users)
      }
      if (payload.type === 'authorized') {
        const users = state.appUsers.filter(f => f.id)
        state.appUsers = users.concat(payload.users)
      }
    },
  },
  actions: {
    setUserLoginTimestamp(_, payload) {
      const account = { id: payload.accountId }
      const data = { lastLogin: new Date() }
      updateDoc({ account, collection: 'users', docId: payload.userId, data })
    },
    syncAppRoles({ commit }, account) {
      const appRoles = []
      onSnapshot(query(collection(db, `roles/accounts/${account.id}`)), response => {
        response.forEach(retrievedDoc => {
          appRoles.push(retrievedDoc.data())
        })
        commit('setAppRoles', appRoles)
        commit('syncList', 'users/syncAppRoles', { root: true })
      })
    },

    syncAppUsers({ commit }, account) {
      // User Snapshots
      onSnapshot(query(collection(db, `users/accounts/${account.id}`)), async response => {
        let appUsers = []
        response.forEach(retrievedDoc => {
          // Ignore Subcollection 'waitingOnAccountCreation'
          if (retrievedDoc.id !== 'waitingOnAccountCreation') {
            appUsers.push(retrievedDoc.data())
          }
        })
        appUsers = appUsers.map(m => ({ ...m, name: `${m.firstName} ${m.lastName}` }))
        appUsers = firestoreTimestamps(appUsers)
        const payload = { users: appUsers, type: 'activeUsers' }
        commit('setAppUsers', payload)
        commit('syncList', 'users/syncAppUsers', { root: true })
      })

      // If RetrievedDoc IS 'waitingOnAccountCreation' Then Parse Waiting Account Creations
      onSnapshot(
        query(collection(db, `users/accounts/${account.id}/waitingOnAccountCreation/waitingOnAccountCreation`)),
        response => {
          const authorizedUsers = []
          response.forEach(retrievedDoc => {
            authorizedUsers.push(retrievedDoc.data())
          })
          const users = authorizedUsers.map(m => ({
            ...m,
            waitingOnAccountCreation: true,
          }))
          const payload = { users, type: 'authorized' }
          commit('setAppUsers', payload)
          commit('syncList', 'users/syncAppUsers', { root: true })
        },
      )
    },

    async createTempUserProfile({ rootState }, payload) {
      // Create Random Password
      const tempPassword = (Math.random() + 1).toString(36).substring(2)
      const passEncrypted = await encryptData(tempPassword)
      const { linkType } = payload
      const tempProfile = {
        ...payload,
        created: new Date(),
        enabled: true,
        timeZone: 'UseLocalTime',
      }
      tempProfile.tempPassword = passEncrypted.data

      // Create User Profile in Firestore
      writeDoc({
        collection: 'users',
        docId: 'waitingOnAccountCreation',
        subCollection: 'waitingOnAccountCreation',
        subCollectionDocId: payload.email,
        data: tempProfile,
      }).then(response => {
        console.log('Wrote User to DB: ', response)
      })
      const fromName = `${rootState.user.firstName} ${rootState.user.lastName}`
      const templateId =
        linkType === 'Snowflake Oauth Link'
          ? 'd-a1bc656197f24a4883d8f91ab38081fa'
          : 'd-8155ce143b294fb1ab380e3198a71012'
      const url =
        linkType === 'Snowflake Oauth Link'
          ? `${serverConfig.baseUrl}/login?oauth=${rootState.accountProfile.sfid}`
          : `${serverConfig.baseUrl}/account-setup/${rootState.account}:${payload.email}`
      const emailPayload = {
        template_id: templateId,
        personalizations: [
          {
            to: payload.email,
            dynamic_template_data: {
              firstName: payload.firstName,
              sentFrom: fromName,
              temporaryPassword: tempPassword,
              url,
            },
          },
        ],
      }

      // Sends Invitation Email to New User with Temporary

      const sendToFunction = httpsCallable(functions, 'callFunction')
      const response = await sendToFunction({
        functionName: 'sendGridMail',
        payload: emailPayload,
      })

      return response
    },
  },
  getters: {},
  modules: {},
}
