<!-- Create New App Password -->
<template>
  <div>
    <v-card-actions>
      <v-row>
        <v-col
          sm="12"
        >
          <v-alert
            border="left"
            colored-border
            text
            color="primary"
          >
            <strong>Let's add your Snowflake account to your {{ serverConfig.name }} user to get started.</strong>
          </v-alert>
          <v-stepper
            v-model="activeStep"
            class="custom-header"
          >
            <!-- Header -->
            <v-stepper-header>
              <!-- Header: Step 1 -->
              <v-stepper-step
                :complete="activeStep > 1"
                step="1"
              >
                <div class="d-flex align-center">
                  <!-- <span class="text--primary text-4xl font-weight-bold me-3">01</span> -->
                  <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">Snowflake Integration</span>
                    <span class="text--secondary text-xs">Connect Your Snowflake Account</span>
                  </div>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 2 -->
              <v-stepper-step
                :complete="activeStep > 2"
                step="2"
              >
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">Snowflake Account Validated</span>
                    <span class="text--secondary text-xs">Validate Snowflake Account</span>
                  </div>
                </div>
              </v-stepper-step>
            </v-stepper-header>

            <!-- Stepper Items -->
            <v-stepper-items>
              <!-- Stepper Content: Step 1 -->
              <v-stepper-content step="1">
                <v-card
                  outlined
                  class="mb-8 pa-10"
                >
                  <user-snowflake-account-setup
                    v-if="account && user"
                    :account="account"
                    :user="user"
                    @setSnowflakeAccountVerified="setSnowflakeAccountVerified"
                    @setSnowflakeUserDetails="setSnowflakeUserDetails"
                  >
                  </user-snowflake-account-setup>
                  <div class="d-flex justify-end mt-5">
                    <!-- <v-btn
                      v-if="!snowflakeAccountVerified"
                      color="error"
                      class="mr-5"
                      @click="userDeclinedSnowflakeAccountDialog = true"
                    >
                      No Snowflake Account
                    </v-btn> -->
                    <v-btn
                      :disabled="!snowflakeAccountVerified"
                      color="primary"
                      @click="activeStep = 2"
                    >
                      Next
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>

              <!-- Stepper Content: Step 2 -->
              <v-stepper-content step="2">
                <v-card
                  outlined
                  class="mb-8 pa-5"
                >
                  <v-container>
                    <v-row
                      justify="center"
                    >
                      <v-col sm="12">
                        <v-alert
                          type="success"
                          text
                        >
                          Snowflake Account Verified for user: {{ userData.username }}
                        </v-alert>
                      </v-col>
                      <v-col sm="12">
                        <span>You can also add the default ROLE and WAREHOUSE you would like to use when connecting to Snowflake from {{ serverConfig.name }}.</span>
                      </v-col>
                      <v-col
                        md="6"
                        sm="12"
                      >
                        <v-text-field
                          v-model="userData.role"
                          outlined
                          label="Default Role"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col
                        md="6"
                        sm="12"
                      >
                        <v-text-field
                          v-model="userData.warehouse"
                          outlined
                          label="Default Warehouse"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>

                <div class="d-flex justify-space-between">
                  <v-btn
                    outlined
                    @click="activeStep = 1"
                  >
                    Previous
                  </v-btn>
                  <v-btn
                    :loading="loadingFinish"
                    color="primary"
                    @click="finish"
                  >
                    Finish
                  </v-btn>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
      <v-form
        ref="form"
        v-model="valid"
      >
      </v-form>
    </v-card-actions>
    <!-- User Declined Snowflake Account Dialog -->
    <v-dialog
      v-model="userDeclinedSnowflakeAccountDialog"
      persistent
      width="500"
    >
      <user-declined-snowflake-account-vue
        @closeDialog="userDeclinedSnowflakeAccountDialog = false"
        @declined="declinedSnowflakeAccount"
      >
      </user-declined-snowflake-account-vue>
    </v-dialog>
  </div>
</template>

<script>
import { updateDoc } from '@/firestore'
import store from '@/store'
import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'
import serverConfig from '@serverConfig'
import { computed, onMounted, ref } from '@vue/composition-api'
import UserDeclinedSnowflakeAccountVue from './UserDeclinedSnowflakeAccount.vue'
import UserSnowflakeAccountSetup from './UserSnowflakeAccountSetup.vue'

export default {
  components: {
    UserDeclinedSnowflakeAccountVue,
    UserSnowflakeAccountSetup,
  },
  setup() {
    const { router } = useRouter()
    onMounted(() => {
      if (!localStorage.getItem('loggedIn')) {
        console.log('User Not Logged In! Cannot proceed with User Setup')
        router.push('/')
      }
    })
    const activeStep = ref(1)
    const account = computed(() => store.state.accountProfile)
    const user = computed(() => store.state.user)
    const loadingFinish = ref(false)
    const snowflakeAccountVerified = ref(false)
    const userData = {
      username: null,
      role: null,
      warehouse: null,
      password: null,
    }
    const snowflakeUserDetails = ref(JSON.parse(JSON.stringify(userData)))
    const userDeclinedSnowflakeAccountDialog = ref(false)
    const verificationMethod = ref(null)

    const writeCredentials = async () => {
      if (verificationMethod.value === 'oauth' || verificationMethod.value === 'password') {
        let data = {}
        if (verificationMethod.value === 'oauth') {
          delete userData.password
          data = { snowflakeCredentials: { ...userData, oauthVerified: true } }
        }
        if (verificationMethod.value === 'password') {
          data = { snowflakeCredentials: { ...userData } }
        }
        console.log('Writing to DB', {
          collection: 'users',
          docId: user.value.id,
          data,
        })
        console.log('WRITING DATA')
        await updateDoc({
          collection: 'users',
          docId: user.value.id,
          data,
        })

        return null
      }

      return null
    }

    const finish = async () => {
      loadingFinish.value = true

      // Write Snowflake Values and Redirect
      console.log('snowflakeUserDetails', userData)
      const response = await writeCredentials()
      console.log('Response', response)
      loadingFinish.value = false

      // If System Account Needed?
      if (!account.value.snowflakeCredentials) {
        console.log('Need to Set Up System Account...')
        router.push('/account-setup/system-account/')
      } else {
        console.log('Navigating to Snowflake Main Page...')
        router.push('/')
      }
    }

    const setSnowflakeUserDetails = data => {
      console.log('Setting snowflakeUserDetails values', data)
      userData.username = data.username
      userData.role = data.role
      userData.warehouse = data.warehouse
      userData.password = data.password
    }

    const declinedSnowflakeAccount = async () => {
      userDeclinedSnowflakeAccountDialog.value = false
      await updateDoc({
        collection: 'users',
        docId: user.value.email,
        data: { snowflakeCredentials: { accountDeclined: true } },
      })
      activeStep.value = 2
    }

    const setSnowflakeAccountVerified = event => {
      console.log('Passed Event', event)
      if (event === 'oauth' || event === 'password') {
        snowflakeAccountVerified.value = true
        verificationMethod.value = event
      } else {
        snowflakeAccountVerified.value = false
        verificationMethod.value = null
      }
    }

    // Setting New App Password
    const valid = ref(true)
    const form = ref(null)

    const validateForm = () => {
      form.value.validate()
    }

    return {
      loadingFinish,
      userData,
      finish,
      declinedSnowflakeAccount,
      userDeclinedSnowflakeAccountDialog,
      snowflakeUserDetails,
      setSnowflakeUserDetails,
      snowflakeAccountVerified,
      setSnowflakeAccountVerified,
      user,
      account,
      activeStep,
      serverConfig,
      valid,
      form,
      validateForm,
      required,
      UserSnowflakeAccountSetup,
      UserDeclinedSnowflakeAccountVue,
    }
  },
}
</script>
