<template>
  <div>
    <v-card
      outlined
      class="mb-8 pa-5"
    >
      <v-row>
        <v-col sm="12">
          <v-alert
            dark
            color="primary"
            :icon="icons.mdiAccountDetails"
          >
            <strong>Complete your {{ serverConfig.name }} profile</strong>
          </v-alert>
        </v-col>
        <v-col sm="12">
          <v-alert
            border="left"
            color="primary"
            dark
            text
          >
            <strong>Looks like this is your first time logging into {{ serverConfig.name }}. Help us get started by verifying the information below (retrieved from Snowflake) and add any missing information.</strong>
          </v-alert>
        </v-col>
        <v-col sm="12">
          <v-alert
            dark
            color="primary"
          >
            <strong>01: Profile Details</strong>
          </v-alert>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            v-model="userData.firstName"
            outlined
            label="First Name"
            required
          >
          </v-text-field>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            v-model="userData.lastName"
            outlined
            label="Last Name"
            required
          >
          </v-text-field>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            v-model="userData.email"
            outlined
            label="Email"
            required
          >
          </v-text-field>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-select
            v-model="userData.timeZone"
            :items="timeZoneList"
            outlined
            label="Time Zone"
            required
          >
          </v-select>
          <span v-if="userData.timeZone === 'UseLocalTime'">Browser Timezone: {{ browserTimezone }}</span>
        </v-col>
        <v-col sm="12">
          <v-alert
            dark
            color="primary"
          >
            <strong>02: Snowflake Settings</strong>
          </v-alert>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            v-model="userData.snowflakeCredentials.role"
            outlined
            label="Default Role"
          >
          </v-text-field>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            v-model="userData.snowflakeCredentials.warehouse"
            outlined
            label="Default Warehouse"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <div class="d-flex justify-end">
        <v-btn
          :loading="loading"
          :disabled="!userData.firstName || !userData.lastName || !userData.email"
          color="primary"
          @click="createNewUser"
        >
          Save
        </v-btn>
      </div>
    </v-card>
    <!-- Getting Results from Snowflake Dialog -->
    <v-dialog
      v-model="showLoadingSnowflakeProfile"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3">
          Getting Snowflake Profile Data...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Email Already Exists Dialog -->
    <v-dialog
      v-model="emailAlreadyExistsDialog"
      width="600"
    >
      <v-card>
        <v-card-title>
          Account Already Exists
        </v-card-title>
        <v-card-text>
          An account for the email {{ userData.email }} already exists. If you would like to add Snowflake Oauth connectivity for this account you must login first.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            @click="navigateToLogin"
          >
            Back to Login Page
          </v-btn>
          <v-btn
            color="primary"
            @click="emailAlreadyExistsDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable function-paren-newline */
import { functions, httpsCallable } from '@/firebaseApp'
import { createAppUser, generateRandomPassword } from '@/functions/authentication'
import { timeZoneAbbr, timeZoneList } from '@/functions/dates'
import { snowflakeQuery } from '@/snowflake'
import LoadingInterface from '@/views/authentication/LoadingInterface.vue'
import { useRouter } from '@core/utils'
import { mdiAccountDetails } from '@mdi/js'
import serverConfig from '@serverConfig'
import { computed, onMounted, ref } from '@vue/composition-api'

export default {
  setup(_, context) {
    const { router } = useRouter()
    const emailAlreadyExistsDialog = ref(false)
    const snowflakeUsername = localStorage.getItem('snowflakeUsername')
    const showLoadingSnowflakeProfile = ref(false)
    const userObject = {
      firstName: null,
      lastName: null,
      email: null,
      timeZone: null,
      password: null,
      snowflakeCredentials: {
        role: null,
        username: null,
        warehouse: null,
        oauthVerified: true,
      },
    }
    const userData = ref(JSON.parse(JSON.stringify(userObject)))

    const navigateToLogin = () => {
      router.push('/')
    }

    const browserTimezone = computed(() => {
      const abbr = context.root.$moment.tz(new Date(), context.root.$moment.tz.guess()).format('z')

      return timeZoneAbbr[abbr] || abbr
    })

    const accountId = localStorage.getItem('accountId')
    const loading = ref(false)

    const checkIfEmailExists = async () => {
      try {
        const payload = { accountId, email: userData.value.email }
        const sendToFunction = httpsCallable(functions, 'getUser')
        const response = await sendToFunction(payload)

        return response.data
      } catch (err) {
        console.log(err)
        loading.value = false
      }

      return null
    }

    const createNewUser = async () => {
      console.log(`Creating new Account for ${snowflakeUsername}`)
      loading.value = true

      // Check if Email Already Has Account
      const emailExists = await checkIfEmailExists()
      if (emailExists && emailExists.email) {
        emailAlreadyExistsDialog.value = true
        loading.value = false

        return
      }

      // Create The User Account in Firebase Auth using Random Password
      const randomPassword = generateRandomPassword(20)
      userData.value.password = randomPassword

      const firebaseAuthPayload = {
        accountId,
        email: userData.value.email,
        password: userData.value.password,
        userData: userData.value,
        type: 'newUser',
        source: 'oauth',
      }
      const createAppUserResponse = await createAppUser(firebaseAuthPayload)
      console.log('createAppUserResponse', createAppUserResponse)
      loading.value = false
      router.push('/')
    }

    const getUserDataFromSnowflake = async () => {
      showLoadingSnowflakeProfile.value = true

      // Query User Details
      try {
        const snowflakeUserProfile = await snowflakeQuery(`describe user ${snowflakeUsername}`)
        let user = {}
        snowflakeUserProfile.rows.forEach(row => {
          user = { ...user, [row.property]: row.value }
        })
        userData.value.firstName = user.FIRST_NAME
        userData.value.lastName = user.LAST_NAME
        userData.value.email = user.EMAIL
        userData.value.timeZone = 'UseLocalTime'
        userData.value.snowflakeCredentials.username = user.LOGIN_NAME
        userData.value.snowflakeCredentials.role = user.DEFAULT_ROLE
        userData.value.snowflakeCredentials.warehouse = user.DEFAULT_WAREHOUSE
        showLoadingSnowflakeProfile.value = false
        loading.value = false
      } catch (err) {
        showLoadingSnowflakeProfile.value = false
        console.log(err)
      }
    }

    onMounted(() => {
      getUserDataFromSnowflake()
    })

    return {
      showLoadingSnowflakeProfile,
      navigateToLogin,
      browserTimezone,
      emailAlreadyExistsDialog,
      loading,
      timeZoneList,
      createNewUser,
      userData,
      serverConfig,
      icons: {
        mdiAccountDetails,
      },
      LoadingInterface,
    }
  },
}
</script>
