/* eslint-disable object-curly-newline */
import {
  mdiAccountOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiCogTransferOutline,
  mdiDatabaseEdit,
  mdiFamilyTree,
  mdiHomeOutline,
  mdiPipe,
  mdiShieldAccountOutline,
  mdiTableEyeOff,
} from '@mdi/js'

const dashboards = [
  {
    title: 'Dashboards',
    icon: mdiHomeOutline,
    badge: '1',
    badgeColor: 'warning',
    children: [
      {
        title: 'Overview',
        to: 'dashboard-overview',
      },
    ],
  },
]

const snowflakeAdministration = [
  {
    subheader: 'SNOWFLAKE ADMINISTRATION',
  },
  {
    title: 'Snowflake Users',
    icon: mdiAccountOutline,
    to: 'snowflake-users-list',
  },
  {
    title: 'Snowflake Roles',
    icon: mdiShieldAccountOutline,
    to: 'snowflake-roles',
  },
  {
    title: 'Snowflake Tasks',
    icon: mdiCheckboxMarkedCircleOutline,
    to: 'snowflake-tasks',
  },
]

const dataProfiling = [
  {
    subheader: 'DATA PROFILING',
  },
  {
    title: 'Data Profiler',
    icon: mdiDatabaseEdit,
    to: 'data-profiler',
  },
  {
    title: 'Data Lineage',
    icon: mdiFamilyTree,
    to: 'data-lineage',
  },
  {
    title: 'Pipes and Stages',
    icon: mdiPipe,
    to: 'data-pipes-stages',
  },
]

const dataSecurity = [
  {
    subheader: 'SECURITY AND COMPLIANCE',
  },
  {
    title: 'Compliance Rules',
    icon: mdiDatabaseEdit,
    to: 'compliance',
  },
  {
    title: 'Data Masking',
    icon: mdiTableEyeOff,
    to: 'data-masking',
  },
]

const snowBindings = [
  {
    subheader: 'INGESTION AND REVERSE ETL',
  },
  {
    title: 'Snow Bindings',
    icon: mdiCogTransferOutline,
    to: 'snow-bindings',
  },
]

export default [...dashboards, ...snowflakeAdministration, ...dataProfiling, ...dataSecurity, ...snowBindings]
