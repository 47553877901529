<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="userData && userData.avatar"
            :src="userData.avatar"
          ></v-img>
          <v-icon
            v-else
            color="primary"
            size="28"
          >
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="userData && userData.avatar"
              src="@/assets/images/avatars/1.png"
            ></v-img>
            <v-icon
              v-else
              color="primary"
              size="28"
            >
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userData.fullName || userData.username }}
          </span>
          <small class="text-capitalize"><strong>{{ `${userData.firstName} ${userData.lastName}` }}</strong></small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Admin -->
      <v-list-item :to="{ name: 'admin-panel' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountSupervisorCircle }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Admin Panel</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Profile -->
      <v-list-item>
        <!-- :to="{ name: 'apps-user-view', params: { id: 21 } } -->
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Settings -->
      <v-list-item to="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Settings -->
      <v-list-item @click="refreshAllData">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiDatabaseRefreshOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Refresh All Data</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { initiateAllRefreshes } from '@/functions/initiateRefreshes'
import store from '@/store'
import {
  mdiAccountOutline,
  mdiAccountSupervisorCircle,
  mdiChatOutline,
  mdiCheckboxMarkedOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiDatabaseRefreshOutline,
  mdiEmailOutline,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import { computed } from '@vue/composition-api'

export default {
  setup() {
    // const vm = getCurrentInstance().proxy
    // const { router } = useRouter()
    const snowflakeBookmarks = computed(() => store.state.snowflakeData.snowflakeBookmarks)

    const refreshAllData = async () => {
      try {
        console.log('Refreshing All Data')
        await initiateAllRefreshes(snowflakeBookmarks.value)
        console.log('All Data Refreshed Successfully')
      } catch (error) {
        store.dispatch('systemMessages', `<strong>${error}</strong>`)
        throw error
      }
    }

    const logoutUser = () => {
      store.dispatch('logoutUser')

      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem('accessToken')

      // // Remove userData & Ability from localStorage
      // localStorage.removeItem('userData')

      // // localStorage.removeItem('userAbility')
      // store.commit('setUserAbility', null)

      // // Reset ability
      // vm.$ability.update(initialAbility)

      // // Redirect to login page
      // router.push({ name: 'auth-login' })
    }

    return {
      refreshAllData,
      logoutUser,
      userData: computed(() => store.state.user),

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiAccountSupervisorCircle,
        mdiDatabaseRefreshOutline,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
