<template>
  <div>
    <layout-content-vertical-nav
      :nav-menu-items="navMenuItems"
    >
      <!-- SYSTEM MESSAGES -->
      <!-- --------------- -->
      <div
        v-if="systemMessages.length > 0"
        class="mb-3"
      >
        <v-row>
          <v-col
            v-for="message in systemMessages"
            :key="message.id"
            sm="12"
            class="my-0 py-0"
          >
            <v-alert
              :type="message.type"
              :icon="message.icon"
              :dismissible="message.dismissible"
              :text="message.light"
              :border="message.border"
              :color="message.color"
            >
              <!-- eslint-disable vue/no-v-html -->
              <span
                class="font-weight-bold"
                v-html="message.message"
              ></span>
              <!-- If Contains Route -->
              <router-link
                v-if="message.route"
                :to="message.route"
                class="text-decoration-none"
              >
                <v-btn
                  :color="message.light ? message.type : 'white'"
                  small
                  outlined
                  class="ml-3"
                  @click="closeSystemMessage(message, toggle)"
                >
                  <span
                    :class="message.light ? `${message.type}--text` : 'white--text'"
                    class="font-weight-bold"
                  >{{ message.buttonText ? message.buttonText : 'Click Here' }}</span>
                </v-btn>
              </router-link>
              <template
                v-if="message.dismissible"
                v-slot:close="{ toggle }"
              >
                <v-btn
                  small
                  icon
                  dark
                  @click="closeSystemMessage(message, toggle)"
                >
                  <v-icon>{{ icons.mdiCloseCircle }}</v-icon>
                </v-btn>
              </template>
              <!--eslint-enable-->
            </v-alert>
          </v-col>
        </v-row>
      </div>
      <v-alert
        v-if="showDataSyncAlert.length > 0"
        dismissible
        color="primary"
        border="left"
        elevation="2"
        colored-border
      >
        <v-row dense>
          <v-col
            sm="2"
            class="ml-3"
          >
            <v-btn
              icon
              loading
            ></v-btn>
            {{ dataSyncAlertText }}
          </v-col>
          <v-col
            sm="8"
            dense
          >
            <span
              v-for="item in showDataSyncAlert"
              :key="item.type"
            >
              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-3"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.icon }}
                  </v-icon>
                </template>
                <span>{{ item.tooltip }}</span>
              </v-tooltip>
            </span>
          </v-col>
        </v-row>
      </v-alert>
      <slot></slot>
      <!-- Slot: Navbar -->
      <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
        <div
          class="navbar-content-container"
          :class="{'expanded-search': shallShowFullSearch}"
        >
          <!-- Left Content: Search -->
          <div class="d-flex align-center">
            <v-icon
              v-if="$vuetify.breakpoint.mdAndDown"
              class="me-3"
              @click="toggleVerticalNavMenuActive"
            >
              {{ icons.mdiMenu }}
            </v-icon>
            <app-bar-search
              :shall-show-full-search.sync="shallShowFullSearch"
              :data="appBarSearchData"
              :filter="searchFilterFunc"
              :search-query.sync="appBarSearchQuery"
              @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
            ></app-bar-search>
          </div>

          <!-- Right Header Icons -->
          <!-- ------------------ -->

          <div class="d-flex align-center right-row">
            <!--- Execution Role -->
            <v-select
              :value="selectedExecutionRole"
              :items="roleSelectionItems"
              class="mt-6 mr-3"
              rounded
              dense
              outlined
              label="Execution Role"
              @change="setExecutionRole($event)"
            >
            </v-select>
            <!-- Automatic Refreshes Functionality -->
            <v-btn
              text
              icon
              :color="automaticRefreshes ? 'success' : ''"
              @click="!automaticRefreshes ? startAutomaticRefreshes() : endAutomaticRefreshes()"
            >
              <v-icon>{{ icons.mdiCogRefresh }}</v-icon>
            </v-btn>
            <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
            <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
            <app-bar-notification></app-bar-notification>
            <app-bar-user-menu></app-bar-user-menu>
          </div>
        </div>
      </template>

      <!-- Slot: Footer -->
      <template #footer>
        <div class="d-flex justify-space-between">
          <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
            href="#"
            class="text-decoration-none"
          >Tessella Technologies</a><span class="d-none d-md-inline">, All rights Reserved</span></span>
        </div>
      </template>

      <template #v-app-content>
        <app-customizer class="d-none d-md-block"></app-customizer>
      </template>
      <!-- SnackBar -->
      <v-snackbar
        v-model="showSnackBarDialog"
        color="primary"
      >
        <span><strong>{{ snackBarMessage }}</strong></span>
        <template #action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="showSnackBarDialog = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </layout-content-vertical-nav>
    <div>
    </div>
  </div>
</template>

<script>
/* eslint-disable lines-around-comment */
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'
import { executeRefreshesCheck, initiateAllRefreshes } from '@/functions/initiateRefreshes'
import store from '@/store'
import navMenuItems from '@/views/navigation-menu/navigationMenu'
import AppBarNotification from '@/views/ui/components/AppBarNotification.vue'
// App Bar Components
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
// import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { getVuetify } from '@core/utils'
// eslint-disable-next-line object-curly-newline
import { mdiAccountMultipleOutline, mdiCalendarCheckOutline, mdiCloseCircle, mdiCogClockwise, mdiCogRefresh, mdiCurrencyUsd, mdiFileTableBoxOutline, mdiHarddisk, mdiHeartOutline, mdiLogoutVariant, mdiMenu, mdiShieldLockOutline, mdiTableSync } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { computed, ref, watch } from '@vue/composition-api'

export default {
  components: {
    LayoutContentVerticalNav,
    AppCustomizer,

    // App Bar Components
    AppBarSearch,

    // AppBarI18n,
    AppBarThemeSwitcher,
    AppBarUserMenu,
    AppBarNotification,
  },
  setup() {
    const $vuetify = getVuetify()
    const autoRefreshInterval = ref(null)
    const automaticRefreshes = ref(false)
    const showSnackBarDialog = ref(false)
    const snackBarMessage = ref(null)
    // const newExecutionRole = ref(null)

    const closeSystemMessage = message => {
      store.dispatch('removeSystemMessage', message)
    }

    // Set and Monitor Default and Execution Roles For The User
    const roleSelectionItems = computed(() => {
      const currentUser = store.state.user
      const userRoleList = store.getters['snowflakeData/snowflakeUsersAndRoles'].filter(f => f.id === currentUser?.snowflakeCredentials?.username)[0]?.roles || []
      const userRoles = currentUser?.snowflakeCredentials
      if (userRoles?.defaultRole && !userRoleList.includes(userRoles.defaultRole)) userRoleList.push(userRoles.defaultRole)
      if (userRoles?.role && !userRoleList.includes(userRoles.role)) userRoleList.push(userRoles.role)

      return userRoleList
    })
    const selectedExecutionRole = computed(() => store.state.user?.snowflakeCredentials?.role)

    const setExecutionRole = newValue => {
      store.dispatch('setExecutionRole', newValue)
    }

    const systemMessages = computed(() => store.state.systemMessages)

    const startAutomaticRefreshes = (checkIntervalMinutes = 1) => {
      initiateAllRefreshes() // Forces a quick first time refresh
      autoRefreshInterval.value = window.setInterval(executeRefreshesCheck, checkIntervalMinutes * 60 * 1000)
      snackBarMessage.value = 'Automatic Refreshes Enabled... Checking For Data Every 5 Minutes...'
      showSnackBarDialog.value = true
      automaticRefreshes.value = true
    }

    const endAutomaticRefreshes = () => {
      clearInterval(autoRefreshInterval)
      snackBarMessage.value = 'Automatic Refreshes Disabled'
      showSnackBarDialog.value = true
      automaticRefreshes.value = false
    }

    // Control Top Alert For Data Sync Notification
    const showDataSyncAlert = computed(() => {
      const alerts = []
      const loadingStatus = store.state.loadingBanner
      if (loadingStatus.users) alerts.push({ type: 'users', icon: mdiAccountMultipleOutline, tooltip: 'Snowflake Users and Roles' })
      if (loadingStatus.consumption) alerts.push({ type: 'consumption', icon: mdiCurrencyUsd, tooltip: 'Snowflake Consumption' })
      if (loadingStatus.executions) alerts.push({ type: 'executions', icon: mdiCogClockwise, tooltip: 'Snowflake Executions' })
      if (loadingStatus.schema) alerts.push({ type: 'consumption', icon: mdiFileTableBoxOutline, tooltip: 'Snowflake Schema' })
      if (loadingStatus.tableFreshness) alerts.push({ type: 'tableFreshness', icon: mdiTableSync, tooltip: 'Snowflake Table Freshness' })
      if (loadingStatus.storage) alerts.push({ type: 'storage', icon: mdiHarddisk, tooltip: 'Snowflake Storage' })
      if (loadingStatus.tasks) alerts.push({ type: 'tasks', icon: mdiCalendarCheckOutline, tooltip: 'Snowflake Tasks' })
      if (loadingStatus.grants) alerts.push({ type: 'grants', icon: mdiShieldLockOutline, tooltip: 'Snowflake Grants and Permissions' })

      return alerts
    })
    const dataSyncAlertText = computed(() => {
      // Add random text variations for fun
      const textVariations = ['Refreshing Data ...']

      return textVariations[0]
    })

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    return {
      setExecutionRole,
      selectedExecutionRole,
      roleSelectionItems,
      showSnackBarDialog,
      snackBarMessage,
      automaticRefreshes,
      startAutomaticRefreshes,
      endAutomaticRefreshes,
      autoRefreshInterval,
      closeSystemMessage,
      systemMessages,
      showDataSyncAlert,
      dataSyncAlertText,
      navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      icons: {
        mdiTableSync,
        mdiHarddisk,
        mdiCalendarCheckOutline,
        mdiCogClockwise,
        mdiMenu,
        mdiHeartOutline,
        mdiLogoutVariant,
        mdiAccountMultipleOutline,
        mdiCurrencyUsd,
        mdiCloseCircle,
        mdiFileTableBoxOutline,
        mdiCogRefresh,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
