const nodeEnvironment = process.env.NODE_ENV
const environment = nodeEnvironment === 'production' ? 'prod' : 'local'

const serverConfig = {
  name: 'SnowFlow',
  logo: require('@/assets/images/logos/SnowFlowLogo_Small.png'),
  horizontalLogo: require('@/assets/images/logos/SnowFlowLogoHorizontal_Small.png'),
  horizontalLogoDarkTheme: require('@/assets/images/logos/SnowFlowLogoHorizontal_Small_DarkTheme.png'),
  bannerLogo: require('@/assets/images/logos/SnowFlowLogoSquareWithSubHeader_Medium.png'),
  environment,
  functionEnvironment: environment, // local or prod
  baseUrl: environment === 'prod' ? 'https://snowflow.lendio.com' : 'http://localhost:8080',
  snowflakeOauthRedirectURI: environment === 'prod' ? 'https://snowflow.lendio.com/snowflake-oath' : 'http://localhost:8080/snowflake-oath',
  externalAuthHandler: environment === 'prod' ? 'https://snowflow.lendio.com/external-auth' : 'http://localhost:8080/external-auth',
  location: 'us-central1',
  bucket: 'snowflow-lendio.appspot.com',
  localOauthClientId: 'z3uWw9oqv5J3cDuO/R93kbm1G9U=',
  localOauthClientSecret: 'DH7vJCJqiS507dCoFyIgcAEtMzudF0T9w1aQ+hp+GKY=',
}

// Running Function Emulation On Command Line In npm run serve
// FUNCTIONS_EMULATOR_TIMEOUT_SECONDS=540 firebase emulators:start --only functions,pubsub
// npm run build && firebase deploy --only functions && firebase deploy --only hosting

export default serverConfig
