/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
import { auth, db } from '@/firebaseApp'
import { updateDoc } from '@/firestore'
import { dateArray } from '@/functions/dates'
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import { signOut } from 'firebase/auth'
import { collection, doc, getDocs, onSnapshot } from 'firebase/firestore'
import moment from 'moment-timezone'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import snowBindings from './snowBindings'
import snowflakeCache from './snowflakeCache'
import snowflakeData from './snowflakeData'
import users from './users'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    globalDialog: { type: 'warning', show: false, title: null, message: null, buttonText: 'Continue', continueFunction: null },
    executionRole: null,
    stateReady: false,
    dateRangeFilter: [],
    showSampleData: false,
    loadingBanner: { users: false, consumption: false, executions: false, schema: false, tableFreshness: false, storage: false, tasks: false },
    unsubscribe: null,
    account: null,
    accountProfile: null,
    accountSettings: null,
    user: null,
    syncList: [],
    showLoadingInterface: false,
    goToStep: null, // Used when sending user to a specific step in a setup protocol
    systemMessages: [],
  },
  mutations: {
    setGlobalDialog(state, payload) {
      state.globalDialog = payload
    },
    setExecutionRole(state, payload) {
      state.executionRole = payload
    },
    setStateReady(state, payload) {
      state.stateReady = payload
    },
    setDateRangeFilter(state, payload) {
      if (Array.isArray(payload)) {
        state.dateRangeFilter = payload
      } else {
        localStorage.setItem('dateRangeFilterSelected', payload)
        state.dateRangeFilter[state.dateRangeFilter.findIndex(i => i.active)].active = false
        state.dateRangeFilter[state.dateRangeFilter.findIndex(i => i.name === payload)].active = true
      }
    },
    setLoadingBannerState(state, payload) {
      state.loadingBanner[payload.key] = payload.value
    },
    setSystemMessages(state, payload) {
      state.systemMessages.push(payload)
    },
    removeSystemMessage(state, index) {
      state.systemMessages.splice(index, 1)
    },
    showLoadingInterface(state, payload) {
      state.showLoadingInterface = payload
    },
    setGoToStep(state, payload) {
      state.goToStep = payload
    },
    setUnsubscribe(state, payload) {
      state.unsubscribe = payload
    },
    setUser(state, payload) {
      state.user = payload
    },
    syncList(state, payload) {
      if (!state.syncList.includes(payload)) {
        state.syncList.push(payload)
      }
    },
    setUserAbility(state, payload) {
      state.userAbility = payload
    },
    setUserData(state, payload) {
      state.userData = payload
    },
    setAccountId(state, payload) {
      state.account = payload
    },
    setAccountSettings(state, payload) {
      state.accountSettings = payload
    },
    setAccount(state, payload) {
      state.accountProfile = payload
    },
  },
  actions: {
    setExecutionRole({ commit, state }, payload) { // payload = role name
      // Write new Selected Role to Firestore
      updateDoc({ collection: 'users', docId: state.user.id, data: { 'snowflakeCredentials.role': payload } })
      commit('setExecutionRole', payload)
    },
    dateRangeFilter({ commit }) {
      const localDateRangeFilterSelected = localStorage.getItem('dateRangeFilterSelected') || 'This Month'
      const timeZone = JSON.parse(localStorage.getItem('timeZone')).name

      // Date Range Filtering Across Data Elements
      // objectName = 'minute', 'hour', 'day', or 'month'
      // Gets + / - one day for Extra Data For Timezone Adjustments
      const dateRangeOptions = [
        {
          name: 'Today',
          slug: 'today',
          objectType: 'Daily',
          objectName: 'day',
          startDate: moment()
            .tz(timeZone)
            .startOf('day')
            .format(),
          endDate: moment()
            .tz(timeZone)
            .format(),
          active: false,
        },
        {
          name: 'Yesterday',
          slug: 'yesterday',
          objectType: 'Daily',
          objectName: 'day',
          startDate: moment()
            .tz(timeZone)
            .subtract(1, 'day')
            .startOf('day')
            .format(),
          endDate: moment()
            .tz(timeZone)
            .startOf('day')
            .format(),
          active: false,
        },
        {
          name: 'Last 7 Days',
          slug: 'last7Days',
          objectType: 'Daily',
          objectName: 'day',
          startDate: moment()
            .tz(timeZone)
            .subtract(6, 'days')
            .startOf('day')
            .format(),
          endDate: moment()
            .tz(timeZone)
            .format(),
          active: false,
        },
        {
          name: 'Last Month',
          slug: 'lastMonth',
          objectType: 'Daily',
          objectName: 'day',
          startDate: moment()
            .tz(timeZone)
            .subtract(1, 'month')
            .startOf('month')
            .format(),
          endDate: moment()
            .tz(timeZone)
            .startOf('month')
            .format(),
          active: false,
        },
        {
          name: 'This Month',
          slug: 'thisMonth',
          objectType: 'Daily',
          objectName: 'day',
          startDate: moment()
            .tz(timeZone)
            .startOf('month')
            .format(),
          endDate: moment()
            .tz(timeZone)
            .format(),
          active: false,
        },
        {
          name: 'This Quarter',
          slug: 'thisQuarter',
          objectType: 'Monthly',
          objectName: 'month',
          startDate: moment()
            .tz(timeZone)
            .startOf('quarter')
            .format(),
          endDate: moment()
            .tz(timeZone)
            .format(),
          active: false,
        },
        {
          name: 'This Year',
          slug: 'thisYear',
          objectType: 'Monthly',
          objectName: 'month',
          startDate: moment()
            .tz(timeZone)
            .startOf('year')
            .format(),
          endDate: moment()
            .tz(timeZone)
            .format(),
          active: false,
        },

        // {
        //   name: 'Last Year',
        //   slug: 'lastYear',
        //   objectType: 'Monthly',
        //   objectName: 'month',
        //   startDate: moment()
        //     .tz(timeZone)
        //     .subtract(1, 'year')
        //     .startOf('year')
        //     .format(),
        //   endDate: moment()
        //     .tz(timeZone)
        //     .subtract(1, 'year')
        //     .endOf('year')
        //     .format(),
        //   active: false,
        // },
      ]

      // Set Default Active
      const filterIndex = dateRangeOptions.findIndex(i => i.name === localDateRangeFilterSelected)
      dateRangeOptions[filterIndex].active = true
      commit('setDateRangeFilter', dateRangeOptions)
    },

    // Resolve System and Snowflake Errors and Show On Screen
    // Note that the message property can include HTML and can be a string or object
    // Acceptable Object Props - Only "message" is required in the object
    // { type, message, icon, light, dismissible, route, buttonText } [type]: (error, warning, info, success) [light]: If Light Theme
    // route: '/snowflake/user/TYLER.DELANGE' Adds a Button for Routing To A New Page
    systemMessages({ commit, state }, payload) {
      let payloadMessage = null

      // If No Payload - Clear All Messages
      if (!payload) commit('setSystemMessages', [])

      // If Payload Only Contains a String - Create Default Object Around Sting (error - dismissible)
      if (typeof payload === 'string') {
        payloadMessage = {
          type: 'error',
          message: payload,
          icon: null,
          light: false,
          dismissible: true,
        }
      } else {
        payloadMessage = payload
        if (payloadMessage.type === undefined) payloadMessage.type = 'error'
        if (payloadMessage.dismissible === undefined) payloadMessage.dismissible = true
        if (payload.route) payloadMessage.route = payload.route
        if (payload.route) payloadMessage.buttonText = payload.buttonText
      }

      // Make sure duplicate does not already exist
      let currentMessages = state.systemMessages
      currentMessages = currentMessages.map(m => m.message)
      if (currentMessages.includes(payloadMessage.message)) return

      // If Duplicate Does Not Exist Assign an ID and PUSH to State
      payloadMessage.id = Date.now()
      commit('setSystemMessages', payloadMessage)
    },
    removeSystemMessage({ commit, state }, message) {
      // If Account Alert - Remove User From Account DB Object
      if (message.accountAlert) {
        localStorage.setItem('removedSystemMessage', message.message)
        const docStatement = `accountAlert.activeOnUser.${state.user.id}`
        updateDoc({
          collection: 'accounts',
          docId: state.account,
          data: { [docStatement]: false },
        })
      }

      const index = state.systemMessages.findIndex(i => i.id === message.id)
      if (index > -1) commit('removeSystemMessage', index)
    },
    initializeDataSyncs({ state, dispatch }, payload) {
      // Called From 'syncAccountAndUserData'
      const { account } = payload

      // Initiate Date Range Filter
      dispatch('dateRangeFilter')

      // Initiate Refreshes //////
      dispatch('snowflakeData/refreshSnowflakeUsers')
      dispatch('snowflakeData/refreshSnowflakeWarehouses')

      // Initiate Syncs //////
      dispatch('snowflakeData/syncSnowflakeBookmarks')
      dispatch('snowflakeData/syncSnowflakeRoles')
      dispatch('snowflakeData/syncSnowflakeUserRoles')
      dispatch('snowflakeData/syncSnowflakeConsumptionSummary')

      // dispatch('snowflakeData/syncSnowflakeConsumptionDaily')
      // dispatch('snowflakeData/syncSnowflakeConsumptionMonthly')
      // dispatch('snowflakeData/syncSnowflakeStorage')
      dispatch('snowflakeData/syncSnowflakeStorageSummary')
      dispatch('snowflakeData/syncSnowflakeTasks')
      dispatch('snowflakeData/syncSnowflakeExecutionSummary')

      // dispatch('snowflakeData/syncSnowflakeExecutionsDaily')
      // dispatch('snowflakeData/syncSnowflakeExecutionsMonthly')
      dispatch('snowflakeData/syncSnowflakeTableFreshness')
      dispatch('users/syncAppUsers', account)
      dispatch('users/syncAppRoles', account)
      dispatch('snowflakeData/snowflakeContractDetails')

      // Initiate Any Needed snowBinding Data
      if (!state.snowBindings.snowBindingTasks) dispatch('snowBindings/snowBindingTemplates')
      if (!state.snowBindings.snowBindingConnectors) dispatch('snowBindings/syncSnowBindingConnectors')
    },

    // Destroys the Firestore Listener: payload = ['syncPath/syncFunction'] [Optional] Otherwise ALL syncs from 'syncList' will be destroyed
    destroyDocSync({ state, dispatch }, payload) {
      let destroyList = state.syncList
      if (payload) destroyList = payload

      destroyList.forEach(sync => {
        dispatch(sync, 'destroy')
      })
    },
    async syncAccountAndUserData({ state, commit }, payload) {
      // Sync Account Data
      let { account } = payload
      if (!account) {
        account = localStorage.getItem('accountId')
      }
      const unsubscribe = await onSnapshot(doc(db, 'accounts', account), response => {
        const accountData = response.data()
        localStorage.setItem('accountProfile', JSON.stringify(accountData))
        localStorage.setItem('accountId', accountData.id)
        commit('setAccount', accountData)
        commit('setUnsubscribe', unsubscribe)
        commit('syncList', 'syncAccountAndUserData')
      })

      // Sync User Data
      onSnapshot(doc(db, `users/accounts/${account}`, payload.userId), response => {
        const userData = response.data()
        commit('setUser', userData)
        if (!state.executionRole) commit('setExecutionRole', userData?.snowflakeCredentials?.role)
        localStorage.setItem('user', JSON.stringify(userData))
        if (response.data().timeZone !== 'UseLocalTime') {
          // Set Specified Time Zone Settings
          const userTimeZoneArea = userData.timeZone
          localStorage.setItem(
            'timeZone',
            JSON.stringify({
              zone: moment
                .tz(new Date(), userTimeZoneArea)
                .format('z')
                .replace('D', 'S'),
              offset: moment.tz(new Date(), userTimeZoneArea).format('Z'),
              name: userTimeZoneArea,
            }),
          )
        }
      })

      // Sync Account Settings
      const accountSettingDoc = {}
      const accountSettingCollection = await getDocs(collection(db, `accountSettings/accounts/${account}`))
      accountSettingCollection.forEach(document => {
        Object.keys(document.data()).forEach(key => {
          accountSettingDoc[key] = document.data()[key]
        })
      })
      commit('setAccountSettings', accountSettingDoc)
    },
    logoutUser({ commit }) {
      localStorage.removeItem('user')
      localStorage.removeItem('accountProfile')
      signOut(auth)
        .then(() => {
          // Removing Local Storage Items
          commit('setStateReady', false)
          const localStorageItems = { ...localStorage }
          let lsKeys = Object.keys(localStorageItems)

          // Remove Keys to keep
          lsKeys = lsKeys.filter(
            f =>
              f !== 'accountEmail' &&
              f !== 'accountId' &&
              f !== 'authMethod' &&
              f !== 'snowflakeAccountId' &&
              f !== 'materio-active-theme' &&
              f !== 'timeZone' &&
              f !== 'logoutMessage',
          )

          lsKeys.forEach(key => {
            localStorage.removeItem(key)
          })
          console.log('Successful Signout')
          commit('setUser', null)
        })
        .catch(error => {
          console.log('An Error Occurred While Logging the User Out: ', error)
        })
    },
  },
  getters: {
    dateRangeFilter(state) {
      return state.dateRangeFilter.filter(f => f.active)[0]
    },

    // Gets Days To Bind Data To For Time Graphs
    dateRangeFilterDays(state) {
      const { startDate, endDate, objectName } = state.dateRangeFilter.filter(f => f.active)[0]
      let dates = {}
      if (objectName === 'day') {
        const javascript = dateArray(moment(startDate).toDate(), moment(endDate).toDate())
        const block = javascript.map(m => moment(m).format('YYYYMMDD'))
        dates = { javascript, block }
      }
      if (objectName === 'month') {
        const getDates = dateArray(moment(startDate).toDate(), moment(endDate).toDate())
        const javascript = []
        getDates.forEach(date => {
          if (
            moment(date).format('YYYYMMDD') ===
            moment(date)
              .startOf('month')
              .format('YYYYMMDD')
          ) {
            javascript.push(date)
          }
        })
        const block = javascript.map(m => moment(m).format('YYYYMM'))
        dates = { javascript, block }
      }

      return dates
    },
    user(state) {
      return state.user
    },
    userLoggedIn(state) {
      let loggedInState = false
      if (state.user) {
        loggedInState = true
      }

      return loggedInState
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
    users,
    snowflakeCache,
    snowflakeData,
    snowBindings,
  },
})
