/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
import { db } from '@/firebaseApp'
import { firestoreTimestamps, updateDoc } from '@/firestore'
import { distributionHighMediumLow, formatNumbers } from '@/functions'
import { convertKeys } from '@/functions/stringCaseConversions'
import { snowflakeQuery } from '@/snowflake'
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore'
import _ from 'lodash'
import moment from 'moment-timezone'
import { sampleRoles, sampleUserRoles, sampleUsers, sampleWarehouse } from '../sampleData'

export default {
  namespaced: true,
  state: {
    snowflakeDataDaysBack: 7,
    snowflakeBookmarks: {},
    snowflakeUsers: [],
    snowflakeWarehouses: [],
    snowflakeRoles: [],
    snowflakeUserRoles: [],
    snowflakeTasks: [],
    snowflakeExecutionSummary: [],
    snowflakeExecutionsDaily: [],
    snowflakeExecutionsMonthly: [],
    snowflakeConsumptionSummary: [],
    snowflakeConsumptionDaily: [],
    snowflakeConsumptionMonthly: [],
    snowflakeStorageSummary: [],
    snowflakeStorage: [],
    snowflakeTableFreshness: [],
    snowflakeContractDetails: {},
  },

  mutations: {
    setSnowflakeBookmarks(state, payload) {
      state.snowflakeBookmarks = payload
    },
    setSnowflakeUsers(state, payload) {
      state.snowflakeUsers = payload
    },
    setSnowflakeWarehouses(state, payload) {
      state.snowflakeWarehouses = payload
    },
    setSnowflakeRoles(state, payload) {
      state.snowflakeRoles = payload
    },
    setSnowflakeUserRoles(state, payload) {
      state.snowflakeUserRoles = payload
    },
    addSnowflakeUserRoles(state, payload) {
      // Pass { granteeName, role }
      const { granteeName, role } = payload
      state.snowflakeUserRoles.push({ createdOn: new Date(), grantedBy: '', grantedTo: 'USER', granteeName, role })
    },
    setSnowflakeTasks(state, payload) {
      state.snowflakeTasks = payload
    },
    setSnowflakeConsumptionSummary(state, payload) {
      state.snowflakeConsumptionSummary = payload
    },
    setSnowflakeConsumptionDaily(state, payload) {
      state.snowflakeConsumptionDaily = payload
    },
    setSnowflakeConsumptionMonthly(state, payload) {
      state.snowflakeConsumptionMonthly = payload
    },
    setSnowflakeStorageSummary(state, payload) {
      state.snowflakeStorageSummary = payload
    },
    setSnowflakeStorage(state, payload) {
      state.snowflakeStorage = payload
    },
    setSnowflakeExecutionSummary(state, payload) {
      state.snowflakeExecutionSummary = payload
    },
    setSnowflakeExecutionsDaily(state, payload) {
      state.snowflakeExecutionsDaily = payload
    },
    setSnowflakeExecutionsMonthly(state, payload) {
      state.snowflakeExecutionsMonthly = payload
    },
    setSnowflakeTableFreshness(state, payload) {
      state.snowflakeTableFreshness = payload
    },
    setSnowflakeContractDetails(state, payload) {
      state.snowflakeContractDetails = payload
    },
  },
  actions: {
    // Snowflake Users
    // /////////////////////
    async refreshSnowflakeUsers({ commit, rootState }) {
      let snowflakeUsers = await snowflakeQuery({ query: 'show users', runOn: 'account' })
      snowflakeUsers = firestoreTimestamps(snowflakeUsers.rows)
      snowflakeUsers = convertKeys(snowflakeUsers, 'toCamelCase')
      snowflakeUsers = snowflakeUsers.map(m => ({
        ...m,
        id: m.loginName,
        enabled: m.disabled !== 'true',
        displayName: m.displayName || m.loginName,
        hasPassword: m.hasPassword === 'true',
        lastLogin: m.lastSuccessLogin,
      }))
      const currentUser = rootState.user?.snowflakeCredentials
      const match = snowflakeUsers.filter(f => f.loginName === currentUser?.username)[0]
      if (match) {
        const { defaultRole, defaultWarehouse } = match
        if (defaultRole !== currentUser.defaultRole || defaultWarehouse !== currentUser.defaultWarehouse) {
          await updateDoc({ collection: 'users', docId: rootState.user.id, data: { snowflakeCredentials: { ...currentUser, defaultRole, defaultWarehouse } } })
        }
      }
      if (rootState.showSampleData) snowflakeUsers = snowflakeUsers.filter(f => f.loginName !== 'HEVO')
      if (rootState.showSampleData) snowflakeUsers = sampleUsers(snowflakeUsers)
      commit('setSnowflakeUsers', snowflakeUsers)

      // Function Checks if Snowflake User Had Changes on The Server and Syncs Need To Be Made To The DB
      // i.e. default role, warehouse, etc
      const appUsers = rootState.users.appUsers.filter(f => f.id && f.snowflakeCredentials?.username)
      const writePromises = []
      appUsers.forEach(user => {
        const creds = user?.snowflakeCredentials
        const sfUser = snowflakeUsers.filter(f => f.id === creds.username)[0]
        if (sfUser) {
          if (creds.role && sfUser.defaultRole && creds.role !== sfUser.defaultRole) {
            writePromises.push(updateDoc({ collection: 'users', docId: user.id, data: { 'snowflakeCredentials.role': sfUser.defaultRole } }))
          }
          if (creds.warehouse && sfUser.defaultWarehouse && creds.warehouse !== sfUser.defaultWarehouse) {
            writePromises.push(updateDoc({ collection: 'users', docId: user.id, data: { 'snowflakeCredentials.warehouse': sfUser.defaultWarehouse } }))
          }
        }
      })
      await Promise.all(writePromises)
    },

    async refreshSnowflakeWarehouses({ commit, rootState }) {
      let snowflakeWarehouses = await snowflakeQuery({ query: 'show warehouses', runOn: 'account' })
      snowflakeWarehouses = firestoreTimestamps(snowflakeWarehouses.rows)
      snowflakeWarehouses = convertKeys(snowflakeWarehouses, 'toCamelCase')

      // Join on Warehouse Grants
      const promises = []
      snowflakeWarehouses
        .map(m => m.name)
        .forEach(warehouse => {
          promises.push(snowflakeQuery({ query: `show grants on warehouse "${warehouse}"`, runOn: 'account' }))
        })
      const resolveWarehouseGrants = await Promise.all(promises)
      resolveWarehouseGrants.forEach(warehouse => {
        const warehouseIndex = snowflakeWarehouses.findIndex(f => f.name === warehouse.rows[0].name)
        const roleGrants = []
        warehouse.rows.forEach(grant => {
          roleGrants.push(grant.grantee_name)
        })
        snowflakeWarehouses[warehouseIndex].roles = roleGrants
      })
      if (rootState.showSampleData && snowflakeWarehouses.length > 0) snowflakeWarehouses = sampleWarehouse(snowflakeWarehouses)
      commit('setSnowflakeWarehouses', snowflakeWarehouses)
    },

    // SYNCS ////////////////
    // ///// ///////////////
    // ///// //////////////

    // SYNC Snowflake Bookmarks
    // /////////////////////
    // Parses User Zone to Key/Value Pairs
    syncSnowflakeBookmarks({ commit, rootState }) {
      const account = rootState.accountProfile
      const { zone } = JSON.parse(localStorage.getItem('timeZone'))
      onSnapshot(doc(db, `bookmarks/accounts/${account.id}`, 'snowflakeBookmarks'), async response => {
        const snowflakeBookmarks = response.data()
        const bookmarks = {}

        // Get Bookmarks Inside User Zone
        if (snowflakeBookmarks[zone]) {
          Object.keys(snowflakeBookmarks[zone]).forEach(key => {
            bookmarks[key] = snowflakeBookmarks[zone][key]
          })
        }

        // Get All Other Bookmarks
        Object.keys(snowflakeBookmarks).forEach(key => {
          if (typeof snowflakeBookmarks[key] === 'number') {
            bookmarks[key] = snowflakeBookmarks[key]
          }
        })
        commit('setSnowflakeBookmarks', bookmarks)
        commit('syncList', 'snowflakeData/syncSnowflakeBookmarks', { root: true })
      })
    },

    // Snowflake Roles
    // ///////////////
    syncSnowflakeRoles({ commit, rootState }) {
      const account = rootState.accountProfile
      onSnapshot(doc(db, `snowflakeRoles/accounts/${account.id}`, 'roles'), async response => {
        let snowflakeRoles = firestoreTimestamps(response.data().roles)

        // Convert Nested GranteeRoles Dates
        snowflakeRoles.forEach((role, roleIdx) => {
          role.granteeRoles.forEach((row, rowIdx) => {
            if (row.grantedAt && row.grantedAt.seconds) {
              snowflakeRoles[roleIdx].granteeRoles[rowIdx].grantedAt = new Date(row.grantedAt.seconds * 1000)
            }
          })
        })
        if (rootState.showSampleData) snowflakeRoles = sampleRoles(snowflakeRoles)
        snowflakeRoles = snowflakeRoles.filter(f => f.name !== undefined)
        commit('setSnowflakeRoles', snowflakeRoles)
        commit('syncList', 'snowflakeData/syncSnowflakeRoles', { root: true })
      })
    },

    syncSnowflakeUserRoles({ commit, state, rootState, dispatch }) {
      const account = rootState.accountProfile
      onSnapshot(doc(db, `snowflakeRoles/accounts/${account.id}`, 'userRoles'), response => {
        let snowflakeUserRoles = firestoreTimestamps(response.data().userRoles)
        if (rootState.showSampleData) snowflakeUserRoles = sampleUserRoles(snowflakeUserRoles)
        commit('setSnowflakeUserRoles', snowflakeUserRoles)
        commit('syncList', 'snowflakeData/syncSnowflakeUserRoles', { root: true })

        // Check For Default User Role And Write To Local Storage
        const appUserId = rootState.user?.snowflakeCredentials.username
        const snowflakeUser = state.snowflakeUsers?.filter(f => f.id === appUserId)[0]
        const roles = snowflakeUserRoles.filter(f => f.granteeName === appUserId)?.map(m => m.role)
        if (appUserId && snowflakeUser && !roles.includes(snowflakeUser.defaultRole)) {
          console.log('appUserId', appUserId)
          console.log('Roles', roles)
          console.log('snowflakeUser', snowflakeUser)
          const message = 'You do not currently have access to your DEFAULT role. Set a new default role in your snowflake profile'
          dispatch('systemMessages', {
            message,
            route: appUserId ? `/snowflake/user/${encodeURI(appUserId)}` : null,
            buttonText: 'FIX',
          }, { root: true })
        }
      })
    },

    // Snowflake Tasks Data
    // /////////////////////
    async syncSnowflakeTasks({ commit, rootState }) {
      const account = rootState.accountProfile
      onSnapshot(doc(db, `snowflakeTasks/accounts/${account.id}`, 'current'), response => {
        let snowflakeTasks = response.data().rows
        if (rootState.showSampleData && snowflakeTasks.length > 0) {
          snowflakeTasks = snowflakeTasks.filter(f => f.database !== 'AUDIT').filter(f => f.database !== 'LAKE_LENDIO').filter(f => f.database !== 'LAKE_BOOKKEEPING')
        }
        commit('setSnowflakeTasks', snowflakeTasks)
        commit('syncList', 'snowflakeData/syncSnowflakeTasks', { root: true })
      })
    },

    // Snowflake Consumption Data
    // /////////////////////

    syncSnowflakeConsumptionSummary({ commit, rootState }) {
      const account = rootState.accountProfile
      const timeZone = JSON.parse(localStorage.getItem('timeZone'))
      const { zone } = timeZone

      onSnapshot(doc(db, `snowflakeConsumption/accounts/${account.id}`, `summary-${zone}`), async response => {
        let summaryConsumption = []
        if (response.data()) {
          const consumptionData = response.data().object
          Object.keys(consumptionData).forEach(warehouse => {
            Object.keys(consumptionData[warehouse]).forEach(executionType => {
              if (executionType.includes('Expanded')) {
                const timestamps = []
                Object.keys(consumptionData[warehouse][executionType]).forEach(timestamp => {
                  timestamps.push({ timestamp, ...consumptionData[warehouse][executionType][timestamp] })
                })
                summaryConsumption.push({ warehouse, executionType, timestamps })
              } else {
                summaryConsumption.push({ warehouse, executionType, ...consumptionData[warehouse][executionType] })
              }
            })
          })
          if (rootState.showSampleData && summaryConsumption.length > 0) summaryConsumption = sampleWarehouse(summaryConsumption)
        }
        commit('setSnowflakeConsumptionSummary', summaryConsumption)
        commit('syncList', 'snowflakeData/syncSnowflakeConsumptionSummary', { root: true })
      })
    },

    syncSnowflakeConsumptionDaily({ commit, rootState }) {
      let consumptionDays = []
      const account = rootState.accountProfile
      const timeZone = JSON.parse(localStorage.getItem('timeZone'))
      const { zone } = timeZone

      // Get X Months Ago - Default 1 But needs at least 7 days of data
      const startDate = moment()
        .subtract(7, 'days')
        .format('YYYYMM')
      const q = query(collection(db, `snowflakeConsumption/accounts/${account.id}`), where('month', '>=', startDate), where('timeZone', '==', zone))
      onSnapshot(q, querySnapshot => {
        querySnapshot.forEach(response => {
          const responseData = Object.values(response.data().rows)
          consumptionDays = [...consumptionDays, ...responseData]
        })
        commit('setSnowflakeConsumptionDaily', consumptionDays)
        consumptionDays = [] // Clears Array Cache
        commit('syncList', 'snowflakeData/syncSnowflakeConsumptionDaily', { root: true })
      })
    },

    syncSnowflakeConsumptionMonthly({ commit, rootState }) {
      let consumptionMonths = []
      const account = rootState.accountProfile
      const timeZone = JSON.parse(localStorage.getItem('timeZone'))
      const { zone } = timeZone

      // Get X Years Ago - Default 1
      const startDate = moment()
        .subtract(1, 'years')
        .format('YYYY')
      const q = query(collection(db, `snowflakeConsumption/accounts/${account.id}`), where('year', '>=', startDate), where('timeZone', '==', zone))
      onSnapshot(q, querySnapshot => {
        querySnapshot.forEach(response => {
          const responseData = Object.values(response.data().rows)
          consumptionMonths = [...consumptionMonths, ...responseData]
        })
        commit('setSnowflakeConsumptionMonthly', consumptionMonths)
        consumptionMonths = [] // Clears Array Cache
        commit('syncList', 'snowflakeData/setSnowflakeConsumptionMonthly', { root: true })
      })
    },

    syncSnowflakeStorageSummary({ commit, rootState }) {
      const account = rootState.accountProfile

      onSnapshot(doc(db, `snowflakeStorage/accounts/${account.id}`, 'summary'), async response => {
        const summaryStorage = []
        const storageData = response.data().object
        Object.keys(storageData).forEach(database => {
          Object.keys(storageData[database]).forEach(schema => {
            summaryStorage.push({ database, schema, ...storageData[database][schema] })
          })
        })
        commit('setSnowflakeStorageSummary', summaryStorage)
        commit('syncList', 'snowflakeData/syncSnowflakeStorageSummary', { root: true })
      })
    },

    syncSnowflakeStorage({ commit, rootState }) {
      const account = rootState.accountProfile

      onSnapshot(doc(db, `snowflakeStorage/accounts/${account.id}`, 'tableStorage'), async response => {
        const storage = []
        const storageData = response.data().object
        Object.keys(storageData).forEach(database => {
          Object.keys(storageData[database]).forEach(schema => {
            Object.keys(storageData[database][schema]).forEach(table => {
              storage.push({ database, schema, table, ...storageData[database][schema][table] })
            })
          })
        })
        commit('setSnowflakeStorage', storage)
        commit('syncList', 'snowflakeData/syncSnowflakeStorage', { root: true })
      })
    },

    // Snowflake Executions Daily
    // //////////////////////////

    syncSnowflakeExecutionSummary({ commit, rootState }) {
      const account = rootState.accountProfile
      const timeZone = JSON.parse(localStorage.getItem('timeZone'))
      const { zone } = timeZone

      onSnapshot(doc(db, `snowflakeExecutions/accounts/${account.id}`, `summary-${zone}`), async response => {
        const summaryExecutions = []
        if (response.data()) {
          const executionsResponse = response.data()
          Object.keys(executionsResponse.object).forEach(user => {
            Object.keys(executionsResponse.object[user]).forEach(warehouse => {
              Object.keys(executionsResponse.object[user][warehouse]).forEach(executionType => {
                if (executionType.includes('Expanded')) {
                  const timestamps = []
                  Object.keys(executionsResponse.object[user][warehouse][executionType]).forEach(timestamp => {
                    timestamps.push({ timestamp, ...executionsResponse.object[user][warehouse][executionType][timestamp] })
                  })
                  summaryExecutions.push({ user, warehouse, executionType, timestamps })
                } else {
                  summaryExecutions.push({ user, warehouse, executionType, ...executionsResponse.object[user][warehouse][executionType] })
                }
              })
            })
          })
        }

        commit('setSnowflakeExecutionSummary', summaryExecutions)
        commit('syncList', 'snowflakeData/syncSnowflakeExecutionSummary', { root: true })
      })
    },

    syncSnowflakeExecutionsDaily({ commit, state, rootState }) {
      let executionsDays = []
      const account = rootState.accountProfile
      const daysBack = state.snowflakeDataDaysBack
      const timeZone = JSON.parse(localStorage.getItem('timeZone'))
      const { zone } = timeZone

      // Get X Months Ago - Default 1 But needs at least 7 days of data
      const startDate = moment()
        .subtract(daysBack, 'days')
        .format('YYYYMM')
      const q = query(collection(db, `snowflakeExecutions/accounts/${account.id}`), where('month', '>=', startDate), where('timeZone', '==', zone))
      onSnapshot(q, querySnapshot => {
        querySnapshot.forEach(response => {
          const responseData = Object.values(response.data().rows)
          executionsDays = [...executionsDays, ...responseData]
        })
        commit('setSnowflakeExecutionsDaily', executionsDays)
        executionsDays = [] // Clears Array Cache
        commit('syncList', 'snowflakeData/syncSnowflakeExecutionsDaily', { root: true })
      })
    },

    // Snowflake Executions Monthly
    // //////////////////////////

    syncSnowflakeExecutionsMonthly({ commit, rootState }) {
      let executionsMonths = []
      const account = rootState.accountProfile
      const timeZone = JSON.parse(localStorage.getItem('timeZone'))
      const { zone } = timeZone

      // Get X Months Ago - Default 1 But needs at least 7 days of data
      const startDate = moment()
        .subtract(1, 'year')
        .format('YYYY')
      const q = query(collection(db, `snowflakeExecutions/accounts/${account.id}`), where('year', '>=', startDate), where('timeZone', '==', zone))
      onSnapshot(q, querySnapshot => {
        querySnapshot.forEach(response => {
          const responseData = Object.values(response.data().rows)
          executionsMonths = [...executionsMonths, ...responseData]
        })
        commit('setSnowflakeExecutionsMonthly', executionsMonths)
        executionsMonths = [] // Clears Array Cache
        commit('syncList', 'snowflakeData/syncSnowflakeExecutionsMonthly', { root: true })
      })
    },

    // Snowflake Table Freshness Data
    // //////////////////////////////

    syncSnowflakeTableFreshness({ commit, rootState }) {
      let tableFreshnessData = []
      const account = rootState.accountProfile

      // const timeZone = JSON.parse(localStorage.getItem('timeZone'))

      // const { zone } = timeZone
      // const dateRangeFilter = Object.assign(rootState.dateRangeFilter.filter(f => f.active)[0])
      const dateType = 'month' // dateRangeFilter.objectType === 'Daily' ? 'month' : 'year'
      const dateBlock = '202205' // dateRangeFilter.objectType === 'Daily' ? moment().format('YYYYMM') : moment().format('YYYY')
      const q = query(collection(db, `snowflakeTableFreshness/accounts/${account.id}`), where(dateType, '==', dateBlock))
      onSnapshot(q, querySnapshot => {
        querySnapshot.forEach(response => {
          const responseData = Object.values(response.data().rows)

          // Payload Includes Additional Days of Data to Conform to Local Timezone
          // Remove Data That Doesn't Conform To Local Timezone
          // :todo
          tableFreshnessData = responseData
        })
        commit('setSnowflakeTableFreshness', tableFreshnessData)
        tableFreshnessData = [] // Clears Array Cache
        commit('syncList', 'snowflakeData/syncSnowflakeTableFreshness', { root: true })
      })
    },

    // Snowflake Contract Details
    // //////////////////////////
    snowflakeContractDetails({ commit, rootState }) {
      let creditCost = 3
      let contractDays = 365
      let perDayCreditAllowance = null
      const account = rootState.accountProfile
      const { contractAmount, contractCredits } = account?.snowflakeSettings
      let { contractStart, contractEnd } = account?.snowflakeSettings

      if (contractStart && contractStart.seconds) {
        contractStart = new Date(contractStart.seconds * 1000)
        if (!contractEnd) {
          contractEnd = moment(contractStart)
            .add(1, 'year')
            .toDate()
        } else {
          contractEnd = new Date(contractEnd.seconds * 1000)
        }

        // contractDays = moment.duration(contractStart.diff(contractEnd)).asDays()
        contractDays = moment(contractStart).diff(contractEnd, 'days') * -1
      } // Calculate Each Credit Cost
      if (contractAmount && contractCredits && Number(contractAmount) > 0 && Number(contractCredits) > 0) {
        creditCost = Number(contractAmount) / Number(contractCredits)

        if (creditCost < 0.5) creditCost = 3
        perDayCreditAllowance = Math.round(Number(contractCredits) / contractDays)

        if (typeof perDayCreditAllowance !== 'number' || perDayCreditAllowance === -Infinity) {
          perDayCreditAllowance = null
        }
      }

      const data = {
        creditCost,
        contractDays,
        perDayCreditAllowance,
        contractAmount,
        contractCredits,
        contractStart,
        contractEnd,
      }
      commit('setSnowflakeContractDetails', data)
    },
  },
  getters: {
    snowflakeConsumptionByFilter(state, none, rootState) {
      // Get Current Date Range From Date Range Filter
      const { slug } = rootState.dateRangeFilter.filter(f => f.active)[0]

      return state.snowflakeConsumptionSummary.filter(f => f.executionType === slug)
    },
    snowflakeExecutionsByFilter(state, none, rootState) {
      // Get Current Date Range From Date Range Filter
      const { slug } = rootState.dateRangeFilter.filter(f => f.active)[0]

      return state.snowflakeExecutionSummary.filter(f => f.executionType === slug)
    },

    // // User Consumption Aggregation by TimePeriod
    // // Get Execution Data And Adds Users To Arrays and Adds Credit and Cost
    // // Warehouse Arrays Reference = [0 - executions, 1 - duration, 2 - % of warehouseTotal, 3 - credits, 4 - cost]
    // snowflakeUserConsumptionDaily(state) {
    //   const type = 'Daily'
    //   const timestampName = 'day'

    //   const userExecutions = state[`snowflakeExecutions${type}`]
    //   const contractData = state.snowflakeContractDetails
    //   const globalConsumption = state[`snowflakeConsumption${type}`]

    //   return userConsumption({ timestampName, userExecutions, contractData, globalConsumption })
    // },
    // snowflakeUserConsumptionMonthly(state) {
    //   const type = 'Monthly'
    //   const timestampName = 'month'

    //   const userExecutions = state[`snowflakeExecutions${type}`]
    //   const contractData = state.snowflakeContractDetails
    //   const globalConsumption = state[`snowflakeConsumption${type}`]

    //   return userConsumption({ timestampName, userExecutions, contractData, globalConsumption })
    // },
    // snowflakeWarehouseConsumptionByFilter(state, none, rootState) {
    //   // Get Current Date Range From Date Range Filter
    //   const { objectType, objectName, startDate, endDate } = rootState.dateRangeFilter.filter(f => f.active)[0]
    //   const dateFormat = objectType === 'Daily' ? 'YYYYMMDD' : 'YYYYMM'

    //   const getWarehouseConsumption = state[`snowflakeConsumption${objectType}`]
    //   console.log('getWarehouseConsumption', getWarehouseConsumption, `snowflakeConsumption${objectType}`)

    //   const getContractData = state.snowflakeContractDetails

    //   let warehouseData = getWarehouseConsumption.filter(f => f[objectName] >= moment(startDate).format(dateFormat) && f[objectName] <= moment(endDate).format(dateFormat))

    //   const groupByWarehouseConsumption = []
    //   warehouseData.forEach(row => {
    //     Object.keys(row.warehouses).forEach(warehouse => {
    //       groupByWarehouseConsumption.push({ warehouse, credits: row.warehouses[warehouse] })
    //     })
    //   })

    //   // Group Warehouses and Sum Data
    //   warehouseData = _(groupByWarehouseConsumption)
    //     .groupBy('warehouse')
    //     .map((m, warehouse) => ({
    //       warehouse,
    //       credits: _.sumBy(m, 'credits'),
    //       cost: Math.round(_.sumBy(m, 'credits') * getContractData.creditCost),
    //     }))
    //     .value()

    //   return _.orderBy(warehouseData, ['credits'], ['desc'])
    // },
    snowflakeUserConsumptionByFilter(state, getters, rootState) {
      // Get Execution Data By Filter
      const { slug } = rootState.dateRangeFilter.filter(f => f.active)[0]
      let executionData = state.snowflakeExecutionSummary.filter(f => f.executionType === slug)

      // Get Contract Data For Credit Cost
      const contractData = state.snowflakeContractDetails

      // Group Data To User Level
      executionData = _(executionData).groupBy('user').map((m, user) => (
        { id: user,
          duration: _.sumBy(m, 'du'),
          executions: _.sumBy(m, 'ex'),
          credits: _.sumBy(m, 'wc'),
          cost: _.sumBy(m, 'wc') * contractData.creditCost,
        })).value()

      // Group into High, Medium, Low Consumption
      const consumptionDist = (_.meanBy(executionData, 'consumption') * 2) / 3
      const executionDist = (_.meanBy(executionData, 'executions') * 2) / 3

      // Assign User into Distribution (High, Medium, Low) Buckets
      const executionDistribution = executionData.map(m => ({
        ...m,
        consumptionBucket: distributionHighMediumLow(m.consumption, consumptionDist),
        executionBucket: distributionHighMediumLow(m.executions, executionDist),
        executionTotalsAbbr: formatNumbers(m.executions),
      }))

      return executionDistribution
    },
    snowflakeUsersAndRoles(state, getters, rootState) {
      // Add Snowflake User Roles / App IDs / To Data Set

      const getSnowflakeUsers = state.snowflakeUsers
      const getSnowflakeUserRoles = state.snowflakeUserRoles
      const { appUsers } = rootState.users
      const users = []

      // Add Roles / App Status / Consumption / Warehouses
      getSnowflakeUsers.forEach(user => {
        // Roles
        const roles = getSnowflakeUserRoles.filter(f => f.granteeName === user.id).map(m => m.role)

        // App Status
        const appUser = appUsers.filter(f => f.snowflakeCredentials.username === user.id)[0]
        const userObject = { ...user, roles }
        if (appUser) userObject.appUserId = appUser.id
        users.push(userObject)
      })

      return users
    },
  },
  modules: {},
}
