// ————————————————————————————————————————————————————————
//* ——— Create Snowflake External Function Integration API
// ————————————————————————————————————————————————————————

export const createSnowflakeIntegrationExternalFunction = `
create or replace api integration snowflow_api_integration
  api_provider = google_api_gateway
  google_audience = 'snowflake-proxy-api-1syxck8879qbd.apigateway.snowflow-lendio.cloud.goog'
  api_allowed_prefixes = ('https://snowlake-external-function-proxy-gateway-3ty3kmf4.uc.gateway.dev')
  enabled = true;

desc integration snowflow_api_integration;
select "property", "property_value" from table(result_scan(last_query_id()))
where "property" in ('API_GCP_SERVICE_ACCOUNT');
-- RECORD THE API_GCP_SERVICE_ACCOUNT property_value
`

// ————————————————————————————————————————————————————————
//* ——— Create Snowflake "LOAD PARQUET DATA" Procedure
// ————————————————————————————————————————————————————————
// Pass in (parquetLocation, destinationTable)
// Example Call: call proc_load_parquet_data('stage_tableau_datasources/extract.parquet', 'WORK.TYLERDELANGE.HYPER_TEST');
// Schema is INFERRED From Parquet File

export const createSnowflakeProcLoadParquetData = schema => `create or replace ${schema}.procedure proc_load_parquet_data(PARQUET_STAGE_LOCATION string, DESTINATION string)
RETURNS VARIANT
LANGUAGE JAVASCRIPT
EXECUTE AS CALLER
as
$$
// /////////////////
// Query Execution
// /////////////////
function executeQuery(query) {
    const rows = [];
    const resultSet = snowflake.createStatement({ sqlText: query }).execute();
    try {
    const columnCount = resultSet.getColumnCount()
    while (resultSet.next()) {
        const row = {}
        for (i=1; i <= columnCount; i++) {
            row[resultSet.getColumnName(i)] = resultSet.getColumnValue(i)
        }
        rows.push(row);
    }
    return rows;
    } catch(err) {
        const errorMessage = \`Could not get query results for query: \${query}\`
        throw errorMessage
    }
}
// /////////////////
// Main Execution
// /////////////////
const inferredQuery = \`
SELECT *
  FROM TABLE(
    INFER_SCHEMA(
      LOCATION=>'@${schema}.\${PARQUET_STAGE_LOCATION}'
      , FILE_FORMAT=>'${schema}.PARQUET_FORMAT'
      )
    );\`
const inferredParquetSchema = executeQuery(inferredQuery)
let selectStatement = inferredParquetSchema.map(m => \`\${m.EXPRESSION} as \${m.COLUMN_NAME}\`)
selectStatement = \`select \${selectStatement} from @\${PARQUET_STAGE_LOCATION} ( FILE_FORMAT => 'PARQUET_FORMAT' );\`
createTableQuery = \`create or replace table \${DESTINATION} as \${selectStatement};\`
const createTableResponse = executeQuery(createTableQuery)

return createTableResponse
$$;`

// ————————————————————————————————————————————————
//* ——— Create Snowflake AUDIT Table Proc Creation
// ————————————————————————————————————————————————
export const createSnowflakeProcCreateAuditTable = schema => `create or replace procedure ${schema}.proc_create_audit_table(SOURCE_TABLE string, TARGET_TABLE string)
RETURNS VARIANT
LANGUAGE JAVASCRIPT
EXECUTE AS CALLER
as
$$
// Note: The Following Function Is Copyright Tessella Technologies And CANNOT Be Distributed, Copied, Or Sold Without Express Written Permission From Tessella Technologies
// Call Example: call proc_create_audit_table('SOURCE_DATABASE.SOURCE_SCHEMA.SOURCE_TABLE', 'TARGET_DATABASE.TARGET_SCHEMA.TARGET_TABLE');
///////////////////////////////////////////////
////////// Query Execution Function //////////
/////////////////////////////////////////////
function executeQuery(query) {
    const rows = [];
    const resultSet = snowflake.createStatement({ sqlText: query }).execute();
    try {
        const columnCount = resultSet.getColumnCount()
        while (resultSet.next()) {
            const row = {}
            for (i=1; i <= columnCount; i++) {
                row[resultSet.getColumnName(i)] = resultSet.getColumnValue(i)
            }
            rows.push(row);
        }
        return rows;
    } catch(err) {
        const errorMessage = \`Could not get query results for query: \${query}\`
        throw errorMessage
    }
}

/////////////////////////////////////
////////// Main Execution //////////
///////////////////////////////////
try {
    // Get Current Warehouse Name
    let warehouse = executeQuery(\`show warehouses\`)
    warehouse = warehouse.filter(f => f.is_current === 'Y')[0].name

    // Get Source Table Column Definition
    let sourceColumns = executeQuery(\`desc table \${SOURCE_TABLE}\`)
    sourceColumns = sourceColumns.map(m => \`"\${m.name}"\`)

    // Setup Naming Variables
    const streamName = \`${schema}.STREAM_AUDIT__\${SOURCE_TABLE.replace(/\\./g, '__')}\`
    const taskName = \`${schema}.TASK_AUDIT__\${SOURCE_TABLE.replace(/\\./g, '__')}\`

    // Load Source Table Into Audit Table
    executeQuery(\`create table \${TARGET_TABLE} as select * from \${SOURCE_TABLE}\`)

    // Create Stream On Source Table
    executeQuery(\`create or replace stream \${streamName} on table \${SOURCE_TABLE}\`)

    // Add MetaData Columns To Audit Table
    executeQuery(\`alter table \${TARGET_TABLE} add METADATA$ACTION string, METADATA$ISUPDATE boolean, METADATA$ROW_ID string;\`)

    // Update MetaData Columns For Data Already Ingested
    executeQuery(\`update \${TARGET_TABLE} set METADATA$ACTION = 'INSERT', METADATA$ISUPDATE = false;\`)

    // Create Task To Load Stream Data To Target Table
    const taskQuery = \`
    CREATE OR REPLACE TASK \${taskName}
    WAREHOUSE = \${warehouse}
    SCHEDULE = 'USING CRON */5 * * * * America/Denver'
    WHEN SYSTEM$STREAM_HAS_DATA('\${streamName}')
    AS
    INSERT INTO \${TARGET_TABLE} (\${sourceColumns.join(',')}, METADATA$ACTION, METADATA$ISUPDATE, METADATA$ROW_ID)
    SELECT \${sourceColumns.join(',')}, METADATA$ACTION, METADATA$ISUPDATE, METADATA$ROW_ID FROM \${streamName};\`
    executeQuery(taskQuery)
    const response = executeQuery(\`alter task \${taskName} resume\`)

    return response
} catch(err) {
    const errorMessage = \`Failed Creating AUDIT Table On \${SOURCE_TABLE}: \${err}\`
    throw errorMessage
}
$$;`

// —————————————————————————————————————————————————————————————————————————————————
//* ——— Returns Schemas, Columns, and Data Type Changes For Two Tables
// —————————————————————————————————————————————————————————————————————————————————
// The JSON returns the following:
// Complete Schema for Table1 AND Table2 [Returned as the Table Name]
// Missing and Exclusive Columns For Each Table [TableName.missingColumns and exclusiveColumns]
// Overall Columns in Common
// Any (in common column) TYPE Differences

export const createSnowflakeProcSchemaDiff = schema => `create or replace procedure ${schema}.proc_schema_diff(TABLE1 STRING, TABLE2 STRING)
RETURNS VARIANT
LANGUAGE JAVASCRIPT
EXECUTE AS CALLER
as
$$
// Note: The Following Function Is Copyright Tessella Technologies And CANNOT Be Distributed, Copied, Or Sold Without Express Written Permission From Tessella Technologies
// /////////////
// SQL EXECUTION
// /////////////
function executeQuery(query) {
    const rows = [];
    const resultSet = snowflake.createStatement({ sqlText: query }).execute();
    try {
        const columnCount = resultSet.getColumnCount()
        while (resultSet.next()) {
        const row = {}
        for (i=1; i <= columnCount; i++) {
        row[resultSet.getColumnName(i)] = resultSet.getColumnValue(i)
        }
        rows.push(row);
        }
        return rows;
    } catch(err) {
        const errorMessage = \`Could not get query results for query: \${query}\`
        throw errorMessage
    }
}
// //////////////////////////////////////////////
// MAIN EXECUTION CODE - FIND SCHEMA DIFFERENCES
// //////////////////////////////////////////////
try {
    const table1Schema = executeQuery(\`desc table \${TABLE1}\`)
    const table2Schema = executeQuery(\`desc table \${TABLE2}\`)

    // ////////////////////////////
    // LOOK FOR SCHEMA DIFFERENCES
    // ////////////////////////////

    // COLUMNS IN COMMON
    // AND COLUMNS NOT IN TABLE2
    const columnsInCommon = []
    const columnsNotInTable1 = []
    const columnsNotInTable2 = []
    table1Schema.forEach(column => {
        const table2ColumnList = table2Schema.map(m => m.name)
        if(table2ColumnList.includes(column.name) && !columnsInCommon.includes(column.name)) columnsInCommon.push(column.name)
        if(!table2ColumnList.includes(column.name) && !columnsNotInTable2.includes(column.name)) columnsNotInTable2.push(column.name)
    })

    // COLUMNS NOT IN TABLE1
    table2Schema.forEach(column => {
        const table1ColumnList = table1Schema.map(m => m.name)
        if(!table1ColumnList.includes(column.name) && !columnsNotInTable1.includes(column.name)) columnsNotInTable1.push(column.name)

    })

    // LOOK FOR DATA TYPE DISCREPANCIES
    const dataTypeDiff = []
    columnsInCommon.forEach(column => {
        const table1Type = table1Schema.filter(f => f.name === column)[0].type
        const table2Type = table2Schema.filter(f => f.name === column)[0].type
        if (table1Type !== table2Type) dataTypeDiff.push({ [column]: { [TABLE1]: table1Type, [TABLE2]: table2Type } })
    })
    return { [TABLE1]: { schema: table1Schema, missingColumns: columnsNotInTable1, exclusiveColumns: columnsNotInTable2 }, [TABLE2]: { schema: table2Schema, missingColumns: columnsNotInTable2, exclusiveColumns: columnsNotInTable1 }, columnsInCommon: columnsInCommon, dataTypeDiff }
} catch (err) {
    const errorMessage = { status: 'ERROR', message: 'Failed To Get Table Columns', error: err.toString() }
    return errorMessage
}
$$;`

// ——————————————————————————————————————————————————————————————————————————
//* ——— Snowflake Function to Get Which Columns Changed Between Two Tables
// ——————————————————————————————————————————————————————————————————————————
//
// Can Exclude Columns From Being Compared if Passed in "EXCLUDE_COLUMNS" ARRAY - These Columns Will Still Be Added If Part Of A "New Row"
// Includes New Rows, Updated Rows, Columns Added, Columns Removed
// Does NOT Look For Changes On Variant Columns - i.e. (VARIANT, ARRAY)
// ToDo: Change to proc_find_table_changes(variant)

export const createSnowflakeProcFindTableChanges = schema => `create or replace procedure ${schema}.proc_find_table_changes(PAYLOAD variant)
RETURNS VARIANT
LANGUAGE JAVASCRIPT
EXECUTE AS CALLER
as
$$
// Note: The Following Function Is Copyright Tessella Technologies And CANNOT Be Distributed, Copied, Or Sold Without Express Written Permission From Tessella Technologies
// Does NOT Look For Changes On Variant Columns - i.e. (VARIANT, ARRAY)
// Example Call
// call sf_function_find_table_changes(PARSE_JSON('{"NEW_TABLE_NAME":"__SNOWBINDINGS_TEMP_1656437522779_TABLE_COMPARISON","CURRENT_TABLE":"__SNOWBINDINGS_STAGING_1656437522779_CURRENTRUN","PAST_TABLE":"__SNOWBINDINGS_STAGING_1656437522779_PASTRUN","BINDING_KEY":"COMPANY_ID","COMPANY_ID":"1656437522779","EXCLUDE_COLUMNS":[ "__INGESTION_EXECUTION_TIMESTAMP", "__INGESTION_SOURCE_KEY", "__INGESTION_TRANSACTION_TYPE" ],"IS_TEMP_TABLE":false}'));
let { CURRENT_TABLE, PAST_TABLE, NEW_TABLE_NAME = 'TEMP_COMPARISON_TABLE', BINDING_KEY, BINDING_ID, EXCLUDE_COLUMNS = [], NULL_EXCLUSIONS_NAME, IS_TEMP_TABLE = true } = PAYLOAD
function executeQuery(query) {
    const rows = [];
    const resultSet = snowflake.createStatement({ sqlText: query }).execute();
    try {
    const columnCount = resultSet.getColumnCount()
        while (resultSet.next()) {
            const row = {}
            for (i=1; i <= columnCount; i++) {
                row[resultSet.getColumnName(i)] = resultSet.getColumnValue(i)
            }
            rows.push(row);
        }
        return rows;
    } catch(err) {
        const errorMessage = \`Could not get query results for query: \${query}\`
        throw errorMessage
    }
}
function checkForNullsDups(table) {
    try {
        let results = executeQuery(\`call sf_function_nulls_duplicates_check('\${table}', '\${BINDING_KEY}')\`)[0];
        results = Object.values(results)[0];
        if (results.duplicates > 0 || results.nulls > 0) {
            throw \`Failed Finding Table Differences: The TABLE_NAME: "\${table}" with UNIQUE_KEY "\${BINDING_KEY}" contains DUPLICATES or/and NULLS: \${JSON.stringify(results)}\`
        } else {
            return null;
        }
    } catch(err) {
        const errorMessage = \`Failed checking for Nulls and Dups on table \${table}: ERROR: \${err}\`;
        throw errorMessage;
    }

}
function tableValidation() {
    // Validate That Both CURRENT and PAST TABLES HAVE DATA AND ARE AVAILABLE
    if (!CURRENT_TABLE || !PAST_TABLE) return { currentTableValidation: { count: 0 }, pastTableValidation: { count: 0 }, validationError: 'FAILED: Missing Table Names CURRENT_TABLE or PAST_TABLE' }
    const tableValidationQuery = \`SELECT * FROM INFORMATION_SCHEMA.TABLES WHERE TABLE_NAME = '\${CURRENT_TABLE}' OR TABLE_NAME = '\${PAST_TABLE}'\`
    let validationResponse = executeQuery(tableValidationQuery)
    validationResponse = validationResponse.map(m => ({ name: m.TABLE_NAME, count: m.ROW_COUNT }))
    let currentTableValidation = validationResponse.filter(f => f.name === CURRENT_TABLE)[0]
    currentTableValidation = currentTableValidation ? { ...currentTableValidation, exists: true } : { name: CURRENT_TABLE, count: 0, exists: false }
    let pastTableValidation = validationResponse.filter(f => f.name === PAST_TABLE)[0]
    pastTableValidation = pastTableValidation ? { ...pastTableValidation, exists: true } : { name: PAST_TABLE, count: 0, exists: false }
    return { currentTableValidation, pastTableValidation }
}
try {
    // Create a Temporary Id For Table Storage
    const tempId = Date.now()
    // Validate That Both CURRENT and PAST TABLES HAVE DATA AND ARE AVAILABLE
    NEW_TABLE_NAME = NEW_TABLE_NAME ? NEW_TABLE_NAME : \`__SNOWBINDINGS_TEMP_\${BINDING_ID || tempId }_TABLE_COMPARISON\`
    const { currentTableValidation, pastTableValidation, validationError } = tableValidation()
    if (currentTableValidation.count > 0 && pastTableValidation.count === 0) {
        executeQuery(\`create or replace \${IS_TEMP_TABLE ? 'temporary' : ''} table \${NEW_TABLE_NAME} as select 'NEW' as __comparison_result, *, null as __changes from \${CURRENT_TABLE}\`)
        return { loadedAt: new Date(), addedColumns: [], removedColumns: [], bindingKeyName: BINDING_KEY, tableCounts: { newRows: currentTableValidation.count, updatedRows: 0 }, excludedColumnsFromChangesMatching: EXCLUDE_COLUMNS, tableName: NEW_TABLE_NAME, is_temp_table: IS_TEMP_TABLE }
    }
    if (currentTableValidation.count === 0 || validationError) return { error: 'No Data To Compare', currentTableValidation, pastTableValidation }

    // Validate That The Binding Key Does Not Contain Duplicates OR Nulls -- Throws ERROR if Tables have Dups or Nulls
    CURRENT_TABLE = \`"\${CURRENT_TABLE}"\`
    PAST_TABLE = \`"\${PAST_TABLE}"\`
    BINDING_KEY = \`"\${BINDING_KEY}"\`
    checkForNullsDups(CURRENT_TABLE);
    checkForNullsDups(PAST_TABLE);

    // Get LoadTimestamp
    const loadedAt = new Date()

    // Get New Rows - BindingKeys Not In Past Table
    // CREATE _temp_new_rows Table
    const newRowsTableName = \`__binding_\${BINDING_ID || tempId }_temp_new_rows\`
    const newRowsQuery = \`create or replace temporary table \${newRowsTableName} as select * from \${CURRENT_TABLE} where \${BINDING_KEY} not in (select distinct \${BINDING_KEY} from \${PAST_TABLE});\`
    executeQuery(newRowsQuery);
    let newRows = executeQuery(\`select count(*) as totalCount from \${newRowsTableName}\`)[0]
    newRows = Object.values(newRows)[0]

    // Find Columns For Current and Past Tables To Find Differences For
    // Variant Type Columns (variant, array, etc) Are Excluded From Column Checks
    // let currentColumns = executeQuery(\`select * from \${CURRENT_TABLE} limit 1\`)[0]
    // Get Current Columns
    let currentColumns = executeQuery(\`desc table \${CURRENT_TABLE}\`)
    const columnObject = {}
    currentColumns.map(m => columnObject[m.name] = ["VARIANT", "ARRAY"].includes(m.type) ? 'variant' : 'string' )
    currentColumns = columnObject
    currentColumnsList = currentColumns ? Object.keys(currentColumns) : []

    // Get Past Columns
    let pastColumns = executeQuery(\`select * from \${PAST_TABLE} limit 1\`)[0]
    pastColumns = pastColumns ? Object.keys(pastColumns) : []

    // Removed Defined Excluded Columns From List and Find Columns in Common between both
    const columnExclusions = EXCLUDE_COLUMNS.map(m => m.toUpperCase())
    let columnsToMatch = currentColumnsList.map(m => { if(!columnExclusions.includes(m.toUpperCase())) return m }).filter(f => f)

    let notInCurrentColumns = pastColumns.map(m => { if(!currentColumnsList.includes(m) && !columnExclusions.includes(m)) return m }).filter(f => f)
    let notInPastColumns = currentColumnsList.map(m => { if(!pastColumns.includes(m) && !columnExclusions.includes(m)) return m }).filter(f => f)
    let variantColumns = Object.keys(currentColumns).map(key => { if(currentColumns[key] === 'variant') return key }).filter(f => f)

    // Remove Columns not in Common -- Added back separately to queries below
    // Wrap COLUMN NAMES in Quotes to qualify column names (i.e. using spacing or invalid characters)
    columnsToMatch = columnsToMatch.filter(f => ![...notInCurrentColumns, ...notInPastColumns, ...variantColumns].includes(f)).map(m => \`"\${m}"\`)
    // return { notInCurrentColumns, notInPastColumns, variantColumns, columnExclusions, columnsToMatch: columnsToMatch.sort() }

    // Find Differences Between Tables
    // Add Back "not in common columns" back at end
    // Add Missing Columns as NULL
    let currentTableSelect = \`\${columnsToMatch}\`
    let pastTableSelect = \`\${columnsToMatch}\`
    if (notInCurrentColumns.length > 0) {
        notInCurrentColumns = notInCurrentColumns.map(m => \`"\${m}"\`)
        currentTableSelect = \`\${currentTableSelect}, \${notInCurrentColumns.map(m => \`null as \${m}\`).join(', ')}\`
        pastTableSelect = \`\${pastTableSelect}, \${notInCurrentColumns.join(', ')}\`
    }
    if (notInPastColumns.length > 0) {
        notInPastColumns = notInPastColumns.map(m => \`"\${m}"\`)
        pastTableSelect = \`\${pastTableSelect}, \${notInPastColumns.map(m => \`null as \${m}\`).join(', ')}\`
        currentTableSelect = \`\${currentTableSelect}, \${notInPastColumns.join(', ')}\`
    }
    if (NULL_EXCLUSIONS_NAME) currentTableSelect = \`\${currentTableSelect} where \${NULL_EXCLUSIONS_NAME} is null\`
    const diffQuery = \`
        create or replace temporary table __temp_binding_keys_\${tempId} as
        select distinct \${BINDING_KEY} as BINDING_KEYS from
        (
            select \${currentTableSelect} from \${CURRENT_TABLE} where \${BINDING_KEY} not in (select \${BINDING_KEY} from \${newRowsTableName})
            MINUS
            select \${pastTableSelect} from \${PAST_TABLE}

        )
    \`
    executeQuery(diffQuery);

    // Join Results of Current and Old Together - UNION - Find Column Changes
    // Create Column Check For Each Column - Using a Current Row Lag on Past Row To See If column has changed
    const columnsToCompare = currentTableSelect.replace(/"|null as/g, '').split(',').map(m => \`"\${m.trim()}"\`)
    const columnLagStatements = []
    columnsToCompare.forEach(column => {
        columnLagStatements.push(\`'\${column}', iff(COALESCE(lag(\${column}::string) over(partition by \${BINDING_KEY} order by __binding_type desc), '') = COALESCE(\${column}::string, ''), null, object_construct('changedTo', \${column}::string, 'changedFrom', lag(\${column}::string) over(partition by \${BINDING_KEY} order by __binding_type desc)))\`)
    })

    // const unionColumns = columnsToMatch.map(m => \`\${m}::string as \${m}\`)
    const updatedRowsTableName = \`__binding_\${BINDING_ID || tempId}_temp_updated_rows\`
    const changesCheckQuery = \`
        create or replace temporary table \${updatedRowsTableName} as
        with data_union as (
            select 'current' as __binding_type, \${currentTableSelect} from \${CURRENT_TABLE} where \${BINDING_KEY} IN (select BINDING_KEYS from __temp_binding_keys_\${tempId})
            union
            select 'past' as __binding_type, \${pastTableSelect} from \${PAST_TABLE}
        )
        select * exclude __binding_type from (
            select *, object_construct( \${columnLagStatements.join(', ')} ) as __changes
            from data_union
        )
        where __binding_type = 'current' and __changes <> {}
        qualify row_number() over(partition by \${BINDING_KEY} order by __binding_type) = 1;
    \`
    executeQuery(changesCheckQuery);
    // Remove __binding_type column - No Longer Needed
    // executeQuery(\`alter table \${updatedRowsTableName} drop column __binding_type;\`)

    let updatedRows = executeQuery(\`select count(*) as totalCount from \${updatedRowsTableName}\`)[0]
    updatedRows = Object.values(updatedRows)[0]

    // Combine Tables
    let unionQuery = ''
    const newRowsQueryStatement = \`select 'NEW' as __comparison_result, *, null as __changes from \${CURRENT_TABLE} where \${BINDING_KEY} in(select distinct \${BINDING_KEY} from \${newRowsTableName})\`
    const updateRowsQueryStatement = \`select 'UPDATE' as comparison_result, a.*, b.__changes from \${CURRENT_TABLE} a join \${updatedRowsTableName} b on a.\${BINDING_KEY} = b.\${BINDING_KEY}\`
    // return {newRowsTableName, updatedRowsTableName, newRowsQueryStatement, updateRowsQueryStatement}
    if (newRows < 1 && updatedRows < 1) return { loadedAt, message: 'No Records Found To Compare' }
    else if (newRows > 0 && updatedRows > 0) unionQuery = \`\${newRowsQueryStatement} union \${updateRowsQueryStatement}\`
    else unionQuery = newRows > 0 ? newRowsQueryStatement : updateRowsQueryStatement
    executeQuery(\`create or replace \${IS_TEMP_TABLE ? 'temporary' : ''} table \${NEW_TABLE_NAME} as \${unionQuery}\`)

    // Clean Up
    executeQuery(\`drop table if exists __temp_binding_keys_\${tempId}\`)

    // Return Output
    return { loadedAt, addedColumns: notInPastColumns, variantColumns, removedColumns: notInCurrentColumns, bindingKeyName: BINDING_KEY, tableCounts: { newRows, updatedRows }, excludedColumnsFromChangesMatching: EXCLUDE_COLUMNS, tableName: NEW_TABLE_NAME, is_temp_table: IS_TEMP_TABLE, NOTE: 'Does Not Look For Changes On Variant Columns' };
}
catch (err)  {
    const errorMessage = { status: 'ERROR', message: 'Failed To Get Table Changes', error: err.toString() }
    return errorMessage
    }
$$;`

// —————————————————————————————————————————————————————————————————————————————————————————————————————————
//* ——— Snowflake Procedure That Collects All SnowBinding Log Data From ingestion_detail or dispatch_detail
//* ——— and creates a data set that can be used as "already loaded values"
// —————————————————————————————————————————————————————————————————————————————————————————————————————————
export const createSnowflakeProcRetrieveSnowBindingLogObjects = schema => `create or replace procedure ${schema}.proc_retrieve_snowbinding_log_objects(PAYLOAD variant)
RETURNS VARIANT
LANGUAGE JAVASCRIPT
EXECUTE AS CALLER
as
$$
// Note: The Following Function Is Copyright Tessella Technologies And CANNOT Be Distributed, Copied, Or Sold Without Express Written Permission From Tessella Technologies
// Sample Call To Function: call proc_retrieve_snowbinding_log_objects(parse_json('{ "logType": "dispatch", "bindingId": "1656437522779" }'))
// Optionally Call: { runId: '', startAt: '', compareTables: true } // Compare Tables Uses the Compare Tables Function To Find Differences
function executeQuery(query) {
    const rows = [];
    const resultSet = snowflake.createStatement({ sqlText: query }).execute();
    try {
    const columnCount = resultSet.getColumnCount()
    while (resultSet.next()) {
        const row = {}
        for (i=1; i <= columnCount; i++) {
            row[resultSet.getColumnName(i)] = resultSet.getColumnValue(i)
        }
        rows.push(row);
    }
    return rows;
    } catch(err) {
        const errorMessage = \`Could not get query results for query: \${query}\`
        throw errorMessage
    }
}

// ///////////////
// Main Execution
// ///////////////
const { logType, bindingId, compareTables } = PAYLOAD
try {
    // Get Columns From Source Table
    // Find If currentRun table exists for binding... If Not Use pastRun table
    let sourceTable = executeQuery(\`show tables like '%__SNOWBINDINGS_STAGING_\${bindingId}%'\`)
    const currentRunTable = sourceTable.filter(f => f.name === \`__SNOWBINDINGS_STAGING_\${bindingId}_CURRENTRUN\`)
    const pastRunTable = sourceTable.filter(f => f.name === \`__SNOWBINDINGS_STAGING_\${bindingId}_PASTRUN\`)
    if (currentRunTable.length < 1 && pastRunTable.length < 1) return { error: 'No currentRun or pastRun table to use for Schema' }
    sourceTable = \`__SNOWBINDINGS_STAGING_\${bindingId}_\${currentRunTable.length > 0 ? 'CURRENTRUN' : 'PASTRUN'}\`
    let sourceColumns = executeQuery(\`desc table \${sourceTable};\`)
    sourceColumns = sourceColumns.map(m => ({ ...m, conversionType: !['ARRAY', 'VARIANT'].includes(m.type) ? 'string' : 'variant' }))

    // Get Source/Target Bindings
    let bindings = executeQuery(\`select job_params:bindings::variant as bindings from snowbindings_dispatch where binding_id = '\${bindingId}' and job_params:bindings is not null order by EXECUTION_START_TIME desc limit 1\`)
    if (!bindings[0] || !bindings[0].BINDINGS) return \`Could not get bindings for SnowBinding: \${bindingId}\`
    bindings = bindings[0].BINDINGS
    bindingKeys = {}
    bindings.forEach(binding => bindingKeys[binding.source] = binding.target)
    bindings = bindingKeys // bindings[sourceKey] = targetKey
    // Get Source Id and Target Id Keys
    let idKeys = executeQuery(\`select binding_keys as keys from snowbindings_\${logType}_detail where binding_id = '\${bindingId}' order by execution_timestamp desc limit 1\`)
    if (!idKeys[0] || !idKeys[0].KEYS.source) return \`No SOURCE_KEY was found in the Dispatch/Ingestion Logs To Use For Matching For Binding_ID \${bindingId}\`
    sourceKey = idKeys[0].KEYS.source
    targetKey = idKeys[0].KEYS.target

    // Query Dispatch/Ingestion Log Tables For sourceObject Records
    // Converts target names to source names
    sourceColumns = sourceColumns.map(column => { if(bindings[column.name]) return column }).filter(f => f)
    columnConversionTypes = {}
    sourceColumns.forEach(column => columnConversionTypes[column.name] = column.conversionType)

    const selectColumns = sourceColumns.map(column => \`GET_IGNORE_CASE(to_object(request:sourceObject), '\${bindings[column.name]}')::\${columnConversionTypes[column.name]} as \${column.name}\`)

    // Get All Possible Log Rows For BindingId
    let selectStatement = \`select execution_timestamp as __ingestion_execution_timestamp, transaction_type as __ingestion_transaction_type, request:sourceObject:\${targetKey}::string as __ingestion_source_key, \${selectColumns.join(', ')} from snowbindings_dispatch_detail where request:sourceObject is not null and binding_id = '\${bindingId}'\`
    if (runId) selectStatement = \`\${selectStatement} and run_id = '\${runId}'\`
    if (startAt) selectStatement = \`\${selectStatement} and execution_start_time >= '\${startAt}'::timestamp\`
    const selectStatementQualify = \`qualify row_number() over(partition by __ingestion_source_key order by __ingestion_EXECUTION_TIMESTAMP desc) = 1\`
    // return selectStatement
    const logsTempTable = \`__SNOWBINDINGS_TEMP_\${bindingId}_LOG_CHANGES\`
    const logData = executeQuery(\`create or replace temp table \${logsTempTable} as \${selectStatement} \${selectStatementQualify}\`)

    // Call Function To Compare Changes Between Tables (ifCompareTables = true)
    if (compareTables) {
        const procQuery = \`call temp_sf_function_find_table_changes(PARSE_JSON('{"NEW_TABLE_NAME":"__SNOWBINDINGS_TEMP_\${bindingId}_TABLE_COMPARISON","CURRENT_TABLE":"__SNOWBINDINGS_STAGING_\${bindingId}_CURRENTRUN","PAST_TABLE":"\${logsTempTable}","BINDING_KEY":"COMPANY_ID","\${sourceKey}":"\${bindingId}","EXCLUDE_COLUMNS":[ "__INGESTION_EXECUTION_TIMESTAMP", "__INGESTION_SOURCE_KEY", "__INGESTION_TRANSACTION_TYPE" ],"IS_TEMP_TABLE":false}'));\`
        const tableComparisonResults = executeQuery(procQuery)
        return tableComparisonResults;
    } else {
        return logsTempTable;
    }
} catch(err) {
    const errorMessage = \`Failed Comparing Logs on Binding \${bindingId}: \${err}\`
    throw errorMessage
}
$$;`

// ——————————————————————————————————————————————————————————————————
//* ——— Load All Snowflake Reserved Words into Snowflake Table
// ——————————————————————————————————————————————————————————————————
// Returns 2 Queries in an Array
export const createSnowflakeReservedWordsTable = schema => {
  const queryCreateTable = `create or replace table ${schema}.snowflake_reserved_words(word string, description string);`
  const queryInsertData = `insert into ${schema}.snowflake_reserved_words (word, description) VALUES
  ('ACCOUNT','Cannot be used as an identifier in a SHOW command (e.g. ‘SHOW … IN <identifier>’).'),
  ('ALL','Reserved by ANSI.'),
  ('ALTER','Reserved by ANSI.'),
  ('AND','Reserved by ANSI.'),
  ('ANY','Reserved by ANSI.'),
  ('AS','Reserved by ANSI.'),
  ('BETWEEN','Reserved by ANSI.'),
  ('BY','Reserved by ANSI.'),
  ('CASE','Cannot be used as column reference in a scalar expression.'),
  ('CAST','Cannot be used as column reference in a scalar expression.'),
  ('CHECK','Reserved by ANSI.'),
  ('COLUMN','Reserved by ANSI.'),
  ('CONNECT','Reserved by ANSI.'),
  ('CONNECTION','Cannot be used as an identifier in a SHOW command (e.g. ‘SHOW … IN <identifier>’).'),
  ('CONSTRAINT','Cannot be used as a column name in CREATE TABLE DDL.'),
  ('CREATE','Reserved by ANSI.'),
  ('CROSS','Cannot be used as table name or alias in a FROM clause.'),
  ('CURRENT','Reserved by ANSI.'),
  ('CURRENT_DATE','Cannot be used as column name (reserved by ANSI).'),
  ('CURRENT_TIME','Cannot be used as column name (reserved by ANSI).'),
  ('CURRENT_TIMESTAMP','Cannot be used as column name (reserved by ANSI).'),
  ('CURRENT_USER','Cannot be used as column name (reserved by ANSI).'),
  ('DATABASE','Cannot be used as an identifier in a SHOW command (e.g. ‘SHOW … IN <identifier>’).'),
  ('DELETE','Reserved by ANSI.'),
  ('DISTINCT','Reserved by ANSI.'),
  ('DROP','Reserved by ANSI.'),
  ('ELSE','Reserved by ANSI.'),
  ('EXISTS','Reserved by ANSI.'),
  ('FALSE','Cannot be used as column reference in a scalar expression.'),
  ('FOLLOWING','Reserved by ANSI.'),
  ('FOR','Reserved by ANSI.'),
  ('FROM','Reserved by ANSI.'),
  ('FULL','Cannot be used as table name or alias in a FROM clause.'),
  ('GRANT','Reserved by ANSI.'),
  ('GROUP','Reserved by ANSI.'),
  ('GSCLUSTER','Cannot be used as an identifier in a SHOW command (e.g. ‘SHOW … IN <identifier>’).'),
  ('HAVING','Reserved by ANSI.'),
  ('ILIKE','Reserved by Snowflake.'),
  ('IN','Reserved by ANSI.'),
  ('INCREMENT','Reserved by Snowflake and others.'),
  ('INNER','Cannot be used as table name or alias in a FROM clause.'),
  ('INSERT','Reserved by ANSI.'),
  ('INTERSECT','Reserved by ANSI.'),
  ('INTO','Reserved by ANSI.'),
  ('IS','Reserved by ANSI.'),
  ('ISSUE','Cannot be used as an identifier in a SHOW command (e.g. ‘SHOW … IN <identifier>’).'),
  ('JOIN','Cannot be used as table name or alias in a FROM clause.'),
  ('LATERAL','Cannot be used as table name or alias in a FROM clause.'),
  ('LEFT','Cannot be used as table name or alias in a FROM clause.'),
  ('LIKE','Reserved by ANSI.'),
  ('LOCALTIME','Cannot be used as column name (reserved by ANSI).'),
  ('LOCALTIMESTAMP','Cannot be used as column name (reserved by ANSI).'),
  ('MINUS','Reserved by Snowflake and others.'),
  ('NATURAL','Cannot be used as table name or alias in a FROM clause.'),
  ('NOT','Reserved by ANSI.'),
  ('NULL','Reserved by ANSI.'),
  ('OF','Reserved by ANSI.'),
  ('ON','Reserved by ANSI.'),
  ('OR','Reserved by ANSI.'),
  ('ORDER','Reserved by ANSI.'),
  ('ORGANIZATION','Cannot be used as an identifier in a SHOW command (e.g. ‘SHOW … IN <identifier>’).'),
  ('QUALIFY','Reserved by Snowflake.'),
  ('REGEXP','Reserved by Snowflake.'),
  ('REVOKE','Reserved by ANSI.'),
  ('RIGHT','Cannot be used as table name or alias in a FROM clause.'),
  ('RLIKE','Reserved by Snowflake.'),
  ('ROW','Reserved by ANSI.'),
  ('ROWS','Reserved by ANSI.'),
  ('SAMPLE','Reserved by ANSI.'),
  ('SCHEMA','Cannot be used as an identifier in a SHOW command (e.g. ‘SHOW … IN <identifier>’).'),
  ('SELECT','Reserved by ANSI.'),
  ('SET','Reserved by ANSI.'),
  ('SOME','Reserved by Snowflake.'),
  ('START','Reserved by ANSI.'),
  ('TABLE','Reserved by ANSI.'),
  ('TABLESAMPLE','Reserved by ANSI.'),
  ('THEN','Reserved by ANSI.'),
  ('TO','Reserved by ANSI.'),
  ('TRIGGER','Reserved by ANSI.'),
  ('TRUE','Cannot be used as column reference in a scalar expression.'),
  ('TRY_CAST','Cannot be used as column reference in a scalar expression.'),
  ('UNION','Reserved by ANSI.'),
  ('UNIQUE','Reserved by ANSI.'),
  ('UPDATE','Reserved by ANSI.'),
  ('USING','Cannot be used as table name or alias in a FROM clause.'),
  ('VALUES','Reserved by ANSI.'),
  ('VIEW','Cannot be used as an identifier in a SHOW command (e.g. ‘SHOW … IN <identifier>’).'),
  ('WHEN','Cannot be used as column reference in a scalar expression.'),
  ('WHENEVER','Reserved by ANSI.'),
  ('WHERE','Reserved by ANSI.'),
  ('WITH','Reserved by ANSI.');`

  return [queryCreateTable, queryInsertData]
}
