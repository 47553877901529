import { auth } from '@/firebaseApp'
import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboard from './dashboard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: to => {
      if (auth.currentUser) return { name: 'dashboard-overview' }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/external-auth',
    name: 'external-auth',
    component: () => import('@/views/authentication/ExternalAuthHandler.vue'),
    props: route => ({ urlProps: route }),
    resource: 'Public',
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/snowflake-oath',
    name: 'snowflake-oath',
    component: () => import('@/views/authentication/snowflakeOauth.vue'),
    props: route => ({ code: route.query.code }),
    resource: 'Public',
    redirectIfLoggedIn: true,
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/account-setup/:id',
    name: 'account-setup',
    component: () => import('@/views/account-setup/AccountSetup.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/account-setup/system-account-setup/:id',
    name: 'system-account-setup',
    component: () => import('@/views/account-setup/AccountSetup.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/new-account-setup/',
    name: 'new-account-setup',
    component: () => import('@/views/account-setup/NewAppAccountSetup.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/admin-panel/',
    name: 'admin-panel',
    component: () => import('@/views/administration/AdminPanel.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/snowflake-administration/roles',
    name: 'snowflake-roles',
    component: () => import('@/views/snowflake-administration/SnowflakeRoles.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/snowflake-administration/tasks',
    name: 'snowflake-tasks',
    component: () => import('@/views/snowflake-administration/SnowflakeTasks.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/data-profiling/data-profiler',
    name: 'data-profiler',
    component: () => import('@/views/data-profiling/DataProfiler.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/data-profiling/data-lineage',
    name: 'data-lineage',
    component: () => import('@/views/data-profiling/DataLineage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/data-profiling/pipes-stages',
    name: 'data-pipes-stages',
    component: () => import('@/views/data-profiling/DataPipesStages.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/data-security/compliance',
    name: 'compliance',
    component: () => import('@/views/data-security/Compliance.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/data-security/data-masking',
    name: 'data-masking',
    component: () => import('@/views/data-security/DataMasking.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/snow-bindings',
    name: 'snow-bindings',
    component: () => import('@/views/snow-bindings/SnowBindings.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/snow-bindings/binding-creation',
    name: 'binding-creation',
    component: () => import('@/views/snow-bindings/binding-creation/BindingCreation.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/snow-bindings/edit-binding/:id',
    name: 'edit-binding',
    component: () => import('@/views/snow-bindings/binding-creation/BindingCreation.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— Snowflake Users ——————————————————
  //

  {
    path: '/snowflake/users/list', // /apps/user/list
    name: 'snowflake-users-list', // apps-user-list
    component: () => import('@/views/snowflake-administration/users/UserList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/snowflake/user/:id',
    name: 'snowflake-user',
    component: () => import('@/views/snowflake-administration/users/user-view/UserView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import('@/views/authentication/LoadingInterface.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/logout',
    name: 'auth-logout',
    component: () => {
      store.dispatch('logoutUser')
    },
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/authentication/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/authentication/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  ...dashboard,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
// router.beforeEach((to, _, next) => {
//   // if (to.name === 'auth-login' && auth.currentUser) {
//   //   return next({ name: 'dashboard-overview' })
//   // }

//   //   const userData = localStorage.getItem('userData')

//   //   const isLoggedIn = userData && localStorage.getItem('accessToken') && store.state.userAbility

//   //   if (!canNavigate(to)) {
//   //     // Redirect to login if not logged in
//   //     if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

//   //     // If logged in => not authorized
//   //     return next({ name: 'misc-not-authorized' })

//   //     // return next({ name: 'misc-not-authorized' })
//   //   }

//   //   // Redirect if logged in
//   //   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//   //     next('/')
//   //   }

//   return next()
// })

export default router
