// import { writeDoc } from '@/firestore'
import { cloudFunction } from '@/functions'
import store from '@/store'
import { getCurrentInstance } from '@vue/composition-api'
import moment from 'moment-timezone'

// const account = store.state.accountProfile || JSON.parse(localStorage.getItem('accountProfile'))
let initiateAllRefreshesRunning = false

//
//* ——— Users and Roles ——————————————————
//

async function refreshSnowflakeUsersAndRoles() {
  if (store.state.loadingBanner.users) {
    console.log('Refresh: "refreshSnowflakeUsersAndRoles" is already running and will not be started again...')

    return null
  }
  try {
    store.commit('setLoadingBannerState', { key: 'users', value: true })
    store.dispatch('snowflakeData/refreshSnowflakeUsers')
    await cloudFunction({ functionName: 'refreshSnowflakeRoles' })
    store.commit('setLoadingBannerState', { key: 'users', value: false })

    return 'Snowflake User Refresh Successful'
  } catch (error) {
    store.commit('setLoadingBannerState', { key: 'users', value: false })
    store.dispatch('systemMessages', `<strong>${error}</strong>`)
    throw error
  }
}

//
//* ——— Executions ——————————————————
//

async function refreshExecutions(intervalType = 'Refresh') {
  // If No Bookmark is Passed a Full Load will Initiate Automatically
  const bookmark = store.state.snowflakeData.snowflakeBookmarks.snowflakeExecutions
  if (store.state.loadingBanner.executions) {
    console.log('Refresh: "refreshExecutions" is already running and will not be started again...')

    return null
  }
  try {
    store.commit('setLoadingBannerState', { key: 'executions', value: true })
    const response = await cloudFunction({ functionName: 'snowflakeExecutions', payload: { intervalType, bookmark: bookmark || null } })
    store.commit('setLoadingBannerState', { key: 'executions', value: false })

    return response
  } catch (error) {
    store.commit('setLoadingBannerState', { key: 'executions', value: false })
    store.dispatch('systemMessages', `<strong>${error}</strong>`)
    throw error
  }
}

//
//* ——— Consumption ——————————————————
//

async function refreshConsumption() {
  // If No Bookmark is Passed a Full Load will Initiate Automatically
  const bookmark = store.state.snowflakeData.snowflakeBookmarks.snowflakeConsumption
  if (store.state.loadingBanner.consumption) {
    console.log('Refresh: "refreshConsumption" is already running and will not be started again...')

    return null
  }
  try {
    store.commit('setLoadingBannerState', { key: 'consumption', value: true })
    const response = await cloudFunction({ functionName: 'snowflakeConsumption', payload: { bookmark: bookmark || null } })
    store.commit('setLoadingBannerState', { key: 'consumption', value: false })

    return response
  } catch (error) {
    store.commit('setLoadingBannerState', { key: 'consumption', value: false })
    store.dispatch('systemMessages', `<strong>${error}</strong>`)
    throw error
  }
}

//
//* ——— Table Freshness ——————————————————
//

async function refreshTableFreshness() {
  if (store.state.loadingBanner.tableFreshness) {
    console.log('Refresh: "refreshTableFreshness" is already running and will not be started again...')

    return null
  }
  try {
    store.commit('setLoadingBannerState', { key: 'tableFreshness', value: true })
    const promises = []
    promises.push(cloudFunction({ functionName: 'refreshTableFreshness', payload: { timeType: 'Daily' } }))
    promises.push(cloudFunction({ functionName: 'refreshTableFreshness', payload: { timeType: 'Monthly' } }))
    const response = await Promise.all(promises)
    store.commit('setLoadingBannerState', { key: 'tableFreshness', value: false })

    return response
  } catch (error) {
    store.commit('setLoadingBannerState', { key: 'tableFreshness', value: false })
    store.dispatch('systemMessages', `<strong>${error}</strong>`)
    throw error
  }
}

//
//* ——— Table Storage ——————————————————
//

async function refreshStorage() {
  if (store.state.loadingBanner.storage) {
    console.log('Refresh: "refreshStorage" is already running and will not be started again...')

    return null
  }
  try {
    store.commit('setLoadingBannerState', { key: 'storage', value: true })
    const response = await cloudFunction({ functionName: 'snowflakeTableStorageRefresh' })
    store.commit('setLoadingBannerState', { key: 'storage', value: false })

    return response
  } catch (error) {
    store.commit('setLoadingBannerState', { key: 'storage', value: false })
    store.dispatch('systemMessages', `<strong>${error}</strong>`)
    throw error
  }
}

//
//* ——— Tasks ——————————————————
//

async function refreshTasks() {
  if (store.state.loadingBanner.tasks) {
    console.log('Refresh: "refreshTasks" is already running and will not be started again...')

    return null
  }
  try {
    store.commit('setLoadingBannerState', { key: 'tasks', value: true })
    const response = await cloudFunction({ functionName: 'snowflakeTasksRefresh' })
    store.commit('setLoadingBannerState', { key: 'tasks', value: false })

    return response
  } catch (error) {
    store.commit('setLoadingBannerState', { key: 'tasks', value: false })
    store.dispatch('systemMessages', `<strong>${error}</strong>`)
    throw error
  }
}

async function refreshGrants() {
  if (store.state.loadingBanner.grants) {
    console.log('Refresh: "refreshGrants" is already running and will not be started again...')

    return null
  }
  try {
    store.commit('setLoadingBannerState', { key: 'grants', value: true })
    const response = await cloudFunction({ functionName: 'refreshRoleGrants' })
    store.commit('setLoadingBannerState', { key: 'grants', value: false })

    return response
  } catch (error) {
    store.commit('setLoadingBannerState', { key: 'grants', value: false })
    store.dispatch('systemMessages', `<strong>${error}</strong>`)
    throw error
  }
}

// ————————————————————————————————————
//* ——— Initiate ALL Refreshes
// ————————————————————————————————————

// Initiates multiple refreshes
async function initiateAllRefreshes() {
  if (!store.state.stateReady) return null
  if (initiateAllRefreshesRunning) {
    console.log('initiateAllRefreshesRunning is already running and will not start again.')

    return null
  }
  initiateAllRefreshesRunning = true
  const promises = []
  promises.push(refreshSnowflakeUsersAndRoles())
  promises.push(refreshExecutions())
  promises.push(refreshConsumption())

  // promises.push(refreshTableFreshness())
  promises.push(refreshStorage())
  promises.push(refreshTasks())
  promises.push(refreshGrants())

  const promiseResponse = await Promise.all(promises)
  initiateAllRefreshesRunning = false

  return promiseResponse
}

// ————————————————————————————————————
//* ——— Refresh Checks (By Bookmark Time)
// ————————————————————————————————————

function executeRefreshesCheck(thresholdMinutes = 5) {
  if (store.state.stateReady) return null // REMOVE
  if (!store.state.stateReady) return null

  // Avoid Automatic Refresh Checks On Specific URLS
  const vm = getCurrentInstance().proxy
  const path = vm?.$route?.path
  if (path.includes('snow-binding')) return null
  const bookmarksData = store.state.snowflakeData.snowflakeBookmarks
  if (!bookmarksData || Object.keys(bookmarksData).length === 0) return null

  const bookmarks = {}

  // Add Threshold to Timestamps
  Object.keys(bookmarksData).forEach(key => {
    bookmarks[key] = moment(bookmarksData[key]).add(thresholdMinutes, 'minutes').toDate()
  })

  // // Execute Refreshes
  const usersAndRoles = bookmarks.snowflakeRoles
  const executions = bookmarks.snowflakeExecutions
  const consumption = bookmarks.snowflakeConsumption
  const storage = bookmarks.snowflakeStorage
  const tasks = bookmarks.snowflakeTasks
  const grants = bookmarks.snowflakeGrants

  const includedRefreshes = []

  if (usersAndRoles && usersAndRoles < new Date()) {
    refreshSnowflakeUsersAndRoles()
    includedRefreshes.push('usersAndRoles')
  }
  if (executions && executions < new Date()) {
    refreshExecutions()
    includedRefreshes.push('executions')
  }
  if (consumption && consumption < new Date()) {
    refreshConsumption()
    includedRefreshes.push('consumption')
  }
  if (storage && storage < new Date()) {
    refreshStorage()
    includedRefreshes.push('storage')
  }
  if (tasks && tasks < new Date()) {
    refreshTasks()
    includedRefreshes.push('tasks')
  }
  if (grants && grants < new Date()) {
    refreshGrants()
    includedRefreshes.push('grants')
  }

  if (includedRefreshes.length > 0) {
    const message = `Automatic Refresh Initiated: ${JSON.stringify(includedRefreshes)}`
    console.log(message)
  }

  return null
}

// eslint-disable-next-line import/prefer-default-export
export {
  initiateAllRefreshes, executeRefreshesCheck, refreshSnowflakeUsersAndRoles, refreshExecutions, refreshConsumption, refreshTableFreshness, refreshStorage, refreshTasks, refreshGrants,
}
