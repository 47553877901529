<template>
  <v-dialog
    v-model="dialog.show"
    persistent
    width="500"
  >
    <v-card>
      <v-card-title class="headline">
        {{ dialog.title }}
      </v-card-title>
      <v-card-text>{{ dialog.message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="warning"
          outlined
          @click="closeDialog"
        >
          CANCEL
        </v-btn>
        <v-btn
          color="error"
          @click="continueDialog()"
        >
          {{ dialog.buttonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import store from '@/store'
import { computed } from '@vue/composition-api'

export default {
  setup() {
    const dialog = computed(() => store.state.globalDialog)
    const closeDialog = () => {
      store.commit('setGlobalDialog', { type: 'warning', show: false, title: null, message: null, buttonText: 'Continue', continueFunction: null })
    }
    const continueDialog = () => {
      dialog.value.continueFunction()
      closeDialog()
    }

    return {
      continueDialog,
      closeDialog,
      dialog,
    }
  },
}
</script>

<style lang="scs" scoped>
</style>
