import { functions, httpsCallable } from '@/firebaseApp'
import { updateBindingDoc } from '@/firestore'
import serverConfig from '@serverConfig'

// ————————————————————————————————————————————————————————————————————————————————————————————————
//* ——— Manages Arrays of afterJob Values - Tasks Scheduled to Run After Other Tasks
// ————————————————————————————————————————————————————————————————————————————————————————————————
// Writes / Removes from both the task and afterJob (task)
// Provide: { account, task, lastAfterJobTask, newAfterJobTask } // Provide FULL Task Objects or Null For Add/Delete
// ————————————————————————————————————————————————————————————————————————————————————————————————
export const setAfterJobSchedule = async ({ account, bindingId, cron, lastAfterJobTask, newAfterJobTask, newCloudSchedule }) => {
  try {
    const lastAfterJobTaskId = lastAfterJobTask?.id || null
    const newAfterJobTaskId = newAfterJobTask?.id || null

    // Remove From Previously Executed Task
    if (lastAfterJobTaskId && lastAfterJobTaskId !== newAfterJobTaskId) {
      const removeFromExecutionsArray = lastAfterJobTask.afterJobCompletionExecutions.filter(f => f.bindingId !== bindingId)
      const dbPayload = { afterJobCompletionExecutions: removeFromExecutionsArray }
      await updateBindingDoc({ account, docId: lastAfterJobTaskId, data: dbPayload })
    }

    // Update New "After" Task Array
    if (newAfterJobTaskId && lastAfterJobTaskId !== newAfterJobTaskId) {
      // Get Last Task Data
      const executionsArray = newAfterJobTaskId.afterJobCompletionExecutions || []
      const match = executionsArray.filter(f => f.bindingId === bindingId)[0]
      if (!match) executionsArray.push({ bindingId, enabled: true })
      const newExecutionsPayload = { afterJobCompletionExecutions: executionsArray }
      await updateBindingDoc({ account, docId: newAfterJobTaskId, data: newExecutionsPayload })
    }

    // Update Task Binding
    if (newCloudSchedule || cron === 'afterJob' || (!cron && newAfterJobTaskId)) {
      // eslint-disable-next-line no-nested-ternary
      const newCronValue = newAfterJobTaskId ? 'afterJob' : newCloudSchedule ? newCloudSchedule.cron : cron && cron !== 'afterJob' ? cron : null
      let scheduleCreation = { afterJob: newAfterJobTaskId, cron: newCronValue }
      if (newCloudSchedule) scheduleCreation = { ...scheduleCreation, ...newCloudSchedule }
      await updateBindingDoc({ account, docId: bindingId, data: { cron: newCronValue, scheduleCreation } })
    }
  } catch (error) {
    const errorMessage = `Failed to Write afterJob Schedule. ERROR: ${error}`
    console.trace('\u001b[1;31m', errorMessage)
    throw errorMessage
  }
}

// eslint-disable-next-line import/prefer-default-export
// ——————————————————————————————————————————
//* ——— Executes Binding PubSub Execution
// ——————————————————————————————————————————
export const executeBindingFunction = async sentPayload => {
  const account = JSON.parse(localStorage.getItem('accountProfile'))
  const { bindingId, historicalLoad, json } = sentPayload
  const snowBindingFunction = httpsCallable(functions, 'executeSnowBindingPubSubHttp')
  const payload = { accountId: account.id, bindingId }
  if (serverConfig.environment === 'local') payload.localEmulation = true
  if (json) payload.json = json
  if (historicalLoad) payload.historicalLoad = true
  const response = await snowBindingFunction(payload)

  return response
}

export const parseSnowBindingEvents = bindingEvents => {
  const object = {}
  let runs = []
  Object.keys(bindingEvents).forEach(key => {
    // If Property Is a Run Event
    if (typeof bindingEvents[key] === 'object' && bindingEvents[key]?.status) {
      runs.push(bindingEvents[key])
    } else {
      object.bindingId = bindingEvents.bindingId
      object.currentStatus = bindingEvents.currentStatus
    }
  })
  runs = runs.sort((a, b) => a.executionStartTime - b.executionStartTime)
  object.runs = runs

  return object
}

export const snowBindingSchedule = async payload => {
  try {
    const snowBindingScheduleFunction = httpsCallable(functions, 'snowBindingSchedule')
    const response = await snowBindingScheduleFunction(payload)

    return response
  } catch (error) {
    const errorMessage = `Failed Executing SnowBinding Schedule Function. ERROR: ${error}`
    console.trace('\u001b[1;31m', errorMessage)
    throw errorMessage
  }
}

// Finds Parent and Changes Job to Enabled/Disabled
export const snowBindingAfterJobChange = async ({ account, task, enabled }) => {
  try {
    console.log({ account, task, enabled })
    const parentTask = task.afterTaskText
    const parentExecutionsArray = parentTask.afterJobCompletionExecutions

    const executionsIndex = parentExecutionsArray.findIndex(i => i.bindingId === task.id)
    parentExecutionsArray[executionsIndex].enabled = enabled

    return await updateBindingDoc({ account: account.id, docId: parentTask.id, data: { afterJobCompletionExecutions: parentExecutionsArray } })
  } catch (error) {
    const errorMessage = `Failed To Change afterJob Document. ERROR: ${error}`
    console.trace('\u001b[1;31m', errorMessage)
    throw errorMessage
  }
}
