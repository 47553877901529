import { writeDoc } from '@/firestore'
import { snowflakeQuery } from '@/snowflake'

export default {
  namespaced: true,
  state: {
    loadingUser: false,
  },
  mutations: {},
  actions: {
    async getSnowflakeExecutions({ commit }) {
      commit('setLoadingState', { key: 'executions', value: true })
      console.log('SENDING QUERY')
      const daysBack = -7
      const rows = await snowflakeQuery(`
      WITH results as (
        -- Get Total Execution in Last 7 Days (to join to Users)
        SELECT to_varchar(START_TIME, 'YYYYMMDD') AS day, user_name as user_name
        , warehouse_name, warehouse_size, execution_status
        , query_type, database_name, schema_name
        , count(*) as executions
        , sum(total_elapsed_time) as total_elapsed_time
        , sum(bytes_scanned) as bytes_scanned
        , sum(bytes_written) as bytes_written
        , sum(credits_used_cloud_services) as credits_used_cloud_services
        FROM snowflake.account_usage.query_history
        where start_time >= date_trunc(day, dateadd('day', ${daysBack}, current_timestamp))
        group by to_varchar(START_TIME, 'YYYYMMDD'), user_name, warehouse_name, warehouse_size, execution_status, query_type, database_name, schema_name
        )

        select day as "day", object_construct('day', day::integer, 'data', array_agg(object_construct('userName', user_name::variant
        , 'warehouseName', warehouse_name::variant, 'warehouseSize', warehouse_size::variant
        , 'executionStatus', execution_status::variant, 'queryType', query_type::variant, 'databaseName', database_name::variant
        , 'schemaName', schema_name::variant, 'executions', executions::variant
        , 'totalElapsedTime', total_elapsed_time::variant, 'bytesScanned', bytes_scanned::variant
        , 'bytesWritten', bytes_written::variant, 'creditsUsedCloudServices', credits_used_cloud_services::string))) as "objects"
        from results
        group by day;`)
      rows.rows.forEach(row => {
        writeDoc({
          collection: 'snowflakeExecutions',
          docId: row.day,
          data: row.objects,
        })
      })
      commit('setLoadingState', { key: 'executions', value: false })
    },

    async refreshSnowflakeUsers({ commit }) {
      // eslint-disable-next-line operator-linebreak
      commit('setLoadingState', { key: 'users', value: true })
      const showUsers = await snowflakeQuery('SHOW USERS;')
      const rows = await snowflakeQuery(`
      WITH roles as (
          select grantee_name, array_agg(distinct role) within group (order by role asc) AS "roles"
          from snowflake.account_usage.grants_to_users
          where deleted_on is null
          group by grantee_name
      )

      SELECT DATE_PART('EPOCH_MILLISECOND', users."created_on") AS "created"
            , users."default_role", users."default_warehouse", iff(users."disabled" = 'true', false, true) AS "enabled"
            , users."display_name", users."email", iff(users."has_password" = 'true', true, false) AS "has_password", DATE_PART('EPOCH_MILLISECOND', users."last_success_login") AS "last_login"
            , users."login_name", iff(users."must_change_password" = 'true', true, false) AS "must_change_password", users."owner", users."locked_until_time"
            , coalesce((iff(roles."roles" is null AND users."default_role" = 'PUBLIC', array_construct(users."default_role"), roles."roles")), array_construct()) as "roles"
      FROM TABLE(RESULT_SCAN('${showUsers.data.queryId}')) users
      left join roles on users."login_name" = roles.grantee_name;`)
      await writeDoc({
        collection: 'snowflakeCache',
        docId: 'snowflakeUsers',
        data: { users: rows.rows },
      })
      commit('setLoadingState', { key: 'users', value: false })
    },

    // Gets All Historical Consumption Data Loaded By Month
    async getSnowflakeWarehouseConsumptionHistorical({ state }) {
      const rows = await snowflakeQuery(`
      WITH DATASET as (
        --- HISTORICAL CONSUMPTION (DAILY) ---
        SELECT WAREHOUSE_NAME
        , TO_DATE(DATE_TRUNC(month, CONVERT_TIMEZONE(${state.account.accountTimezone}, START_TIME))) AS EXECUTION_MONTH
        , TO_DATE(CONVERT_TIMEZONE(${state.account.accountTimezone}, START_TIME)) AS EXECUTION_DATE
        , SUM(CREDITS_USED) AS CREDITS_USED
        FROM SNOWFLAKE.ACCOUNT_USAGE.WAREHOUSE_METERING_HISTORY
        WHERE WAREHOUSE_NAME <> 'CLOUD_SERVICES_ONLY'
        -- WHERE EXECUTION_DATE >= CURRENT_DATE() - 30
        GROUP BY EXECUTION_MONTH, EXECUTION_DATE, WAREHOUSE_NAME
        ORDER BY EXECUTION_MONTH, EXECUTION_DATE, WAREHOUSE_NAME
        )

        SELECT  EXECUTION_MONTH as "executionMonth", object_construct('rows', array_agg(object_construct('executionDate', EXECUTION_DATE::variant, 'warehouseName', WAREHOUSE_NAME::variant, 'creditsUsed', CREDITS_USED::number))) as "rows"
        FROM DATASET
        GROUP BY EXECUTION_MONTH;
      `)
      console.log('ROWS', rows)

      // await writeDoc({
      //   collection: 'snowflakeWarehouseConsumption',
      //   docId: 'snowflakeUsers',
      //   data: { users: rows.rows },
      // })
    },
  },
  getters: {},
  modules: {},
}
