/* eslint-disable import/prefer-default-export */
import { functions, httpsCallable } from '@/firebaseApp'
import { getTokens } from '@/functions/snowflakeOauth'
import { convertJSONDates } from './helpers'

// Pass a previously received snowflakeConnection object to keep the same session
async function snowflakeQuery(request) {
  // Is request a Query or actual Payload
  const requestPayload = request.query || request.queries ? request : { query: request }
  const { query, queries, runOn } = requestPayload
  const account = JSON.parse(localStorage.getItem('accountProfile'))
  const user = JSON.parse(localStorage.getItem('user'))

  // Calling the Query Cloud Function
  const functionName = 'snowflakeQueryCall'
  const sendToFunction = httpsCallable(functions, functionName)
  try {
    const payload = {
      account,
    }
    if (runOn !== 'account') {
      payload.user = user
      if (localStorage.getItem('snowflakeAccessToken')) {
        const accessToken = await getTokens(account) // Get Access Token or New Token if Needed
        const oauth = {
          accessToken,
          snowflakeAccountId: localStorage.getItem('snowflakeAccountId'),
          snowflakeUsername: localStorage.getItem('snowflakeUsername'),
          snowflakeRole: localStorage.getItem('snowflakeScope').split('role:')[1],
        }
        payload.oauth = oauth
      }
    }
    if (query) payload.query = query
    if (queries) payload.queries = queries
    const response = await sendToFunction(payload)
    if (typeof response.data === 'string' || response.data.message) {
      throw response.data.message || response.data
    }

    let snowflakeRows = []
    if (response.data.length > 0) {
      const datesProcesses = []
      response.data.forEach(responseData => {
        datesProcesses.push(convertJSONDates(responseData))
      })
      snowflakeRows = datesProcesses
    } else {
      // Fix JSON dates to JS Objects
      snowflakeRows = convertJSONDates(response.data)
    }

    return snowflakeRows
  } catch (err) {
    const errorMessage = `Snowflake Error: ${err}`
    console.log(errorMessage)

    throw errorMessage
  }
}

export { snowflakeQuery }
