import { writeDoc } from '@/firestore'
import { cloudFunction } from '@/functions'
import { snowflakeQuery } from '@/snowflake'
import { executeQueryHistoryBackfill } from '@/snowflake/snowflakeAdministration'
import store from '@/store'
import moment from 'moment-timezone'
import { createSnowflakeProcFindTableChanges, createSnowflakeProcRetrieveSnowBindingLogObjects } from './snowflakeProcedures'

const tableAbbr = 'sf'

async function initiateFirstTimeLoads(account) {
  if (!account || !account.snowflakeSettings || !account.snowflakeSettings.ownershipSchemaDatabase) {
    const errorMessage = 'There is No Account Schema So First Time Initiation Can Not Begin!'
    throw errorMessage
  }
  const schema = `${account.snowflakeSettings.ownershipSchemaDatabase}.${account.snowflakeSettings.ownershipSchema}`
  const { accountSettings } = store.state

  try {
    // Note that most Snowflake Data Has First Time Load Capabilities Already if There Is No Bookmark Entry
    const promises = []
    console.log('Loading First Time Loads...')

    // Create Required Tables on Snowflake Schema
    // //////////////////////////////////////////

    // Create snowflakeBookmarks Doc
    await writeDoc({ collection: 'bookmarks', docId: 'snowflakeBookmarks', data: {} })

    // Create Query History Table in System Schema
    // Get Last Hour of Data As Starting Point
    const lastHourOfDataTimestamp = moment()
      .startOf('hour')
      .format()
    const createQueryHistoryTableQuery = `
  create or replace transient table ${schema}.query_history_table cluster by (end_time, start_time, user_name, warehouse_name)
  as select * from snowflake.account_usage.query_history where end_time >= '${lastHourOfDataTimestamp}'::timestamp;`
    promises.push(snowflakeQuery({ query: createQueryHistoryTableQuery, runOn: 'account' }))

    // CREATE SETTINGS TABLE ON SNOWFLAKE
    const createSettingsTableQuery = `CREATE TRANSIENT TABLE IF NOT EXISTS ${schema}.${tableAbbr}_SETTINGS
  (type string, name string, value string, modified_at timestamp);`
    promises.push(snowflakeQuery({ query: createSettingsTableQuery, runOn: 'account' }))

    // CREATE LOGS TABLE ON SNOWFLAKE
    const createLogsTableQuery = `CREATE TRANSIENT TABLE IF NOT EXISTS ${schema}.${tableAbbr}_LOGS
  (type string, name string, value string, result string, modified_at timestamp);`
    promises.push(snowflakeQuery({ query: createLogsTableQuery, runOn: 'account' }))

    // CREATE FILE FORMATS NEEDED
    const parquetFileFormatQuery = `CREATE FILE FORMAT ${schema}.PARQUET_FORMAT TYPE = 'PARQUET' COMPRESSION = 'AUTO' BINARY_AS_TEXT = TRUE;`
    promises.push(snowflakeQuery({ query: parquetFileFormatQuery, runOn: 'account' }))

    // CREATE NULLS_DUPLICATES_CHECK Procedure -- Checks Table for Nulls or Duplicates
    // Find Duplicates and Nulls in a Table - Provide a TABLE_NAME and a UNIQUE_KEY *column name* to look through
    const createNullsDuplicatesCheckFunction = `
    create or replace procedure ${schema}.${tableAbbr}_function_nulls_duplicates_check(TABLE_NAME VARCHAR, UNIQUE_KEY VARCHAR)
    RETURNS VARIANT
    LANGUAGE JAVASCRIPT
    EXECUTE AS CALLER
    as
    $$
    // Note: The Following Function Is Copyright Tessella Technologies And CANNOT Be Distributed, Copied, Or Sold Without Express Written Permission From Tessella Technologies
    try {
        const query = \`
        with has_nulls as (
            select count(*) as nulls from \${TABLE_NAME} where \${UNIQUE_KEY} is null
        ),

        has_dups as (
            select sum(dups) - count(*) as duplicates from (
                select count(*) as dups from \${TABLE_NAME}
                group by \${UNIQUE_KEY}
                having count(*) > 1
            )
        )

        select object_construct('table', '\${TABLE_NAME}', 'uniqueKey', '\${UNIQUE_KEY}', 'nulls', (select COALESCE(nulls, 0) from has_nulls), 'duplicates', (select COALESCE(duplicates, 0) from has_dups)) as results;
        \`
        const resultSet = snowflake.createStatement({ sqlText: query }).execute();
        resultSet.next();
        return resultSet.getColumnValue(1)
    } catch (err) {
        const errorMessage = \`Failed to execute procedure NULLS_DUPLICATES_CHECK for TABLE_NAME "\${TABLE_NAME}" with UNIQUE_KEY "\${UNIQUE_KEY}": ERROR: \${err}\`
        throw errorMessage
    }
    $$;
    `
    promises.push(snowflakeQuery({ query: createNullsDuplicatesCheckFunction, runOn: 'account' }))

    promises.push(snowflakeQuery({ query: createSnowflakeProcFindTableChanges(schema), runOn: 'account' }))

    promises.push(snowflakeQuery({ query: createSnowflakeProcRetrieveSnowBindingLogObjects(schema), runOn: 'account' }))

    // SNOWBINDINGS_DISPATCH
    const dispatchCreationQuery = `create or replace table ${schema}.snowbindings_dispatch (run_id string, binding_id string, app_name string, task_name string, task_id string, load_method string, status string, execution_start_time timestamp, execution_end_time timestamp, inserts_count number, updates_count number, processing_count number, static_count number, success_count number, warning_count number, error_count number, errors variant, job_params variant, message string, modified_timestamp timestamp) cluster by (binding_id, task_id, status);`
    promises.push(snowflakeQuery({ query: dispatchCreationQuery, runOn: 'account' }))

    // SNOWBINDINGS_DISPATCH
    const dispatchBatchesCreationQuery = `create or replace table ${schema}.snowbindings_dispatch_batches (batch_id string, run_id string, binding_id string, status string, execution_timestamp timestamp, inserts_count number, updates_count number, processing_count number, static_count number, success_count number, warning_count number, error_count number, errors variant, request variant, response variant, modified_timestamp timestamp) cluster by (batch_id, run_id, binding_id, status);`
    promises.push(snowflakeQuery({ query: dispatchBatchesCreationQuery, runOn: 'account' }))

    // SNOWBINDINGS_DISPATCH_DETAIL
    const dispatchDetailsCreationQuery = `create or replace table ${schema}.snowbindings_dispatch_detail (event_id string, batch_id string, run_id string, binding_id string, source_id string, target_id string, binding_keys variant, transaction_type string, status string, associations variant, request variant, response variant, error string, execution_timestamp timestamp, modified_timestamp timestamp) cluster by (batch_id, run_id, binding_id, source_id, target_id, status, transaction_type);`
    promises.push(snowflakeQuery({ query: dispatchDetailsCreationQuery, runOn: 'account' }))

    // SNOWBINDINGS_INGESTION
    const ingestionCreationQuery = `create or replace table ${schema}.snowbindings_ingestion (run_id string, binding_id string, app_name string, task_name string, task_id string, load_method string, status string, execution_start_time timestamp, execution_end_time timestamp, inserts_count number, updates_count number, static_count number, success_count number, warning_count number, error_count number, errors variant, job_params variant, modified_timestamp timestamp) cluster by (binding_id, task_id, status);`
    promises.push(snowflakeQuery({ query: ingestionCreationQuery, runOn: 'account' }))

    // SNOWBINDINGS_DISPATCH
    const dispatchIngestionCreationQuery = `create or replace table ${schema}.snowbindings_ingestion_batches (batch_id string, run_id string, binding_id string, status string, execution_timestamp timestamp, inserts_count number, updates_count number, static_count number, success_count number, warning_count number, error_count number, errors variant, request variant, response variant, modified_timestamp timestamp) cluster by (batch_id, run_id, binding_id, status);`
    promises.push(snowflakeQuery({ query: dispatchIngestionCreationQuery, runOn: 'account' }))

    // SNOWBINDINGS_INGESTION_DETAIL
    const ingestionDetailsCreationQuery = `create or replace table ${schema}.snowbindings_ingestion_detail (event_id string, batch_id string, run_id string, binding_id string, source_id string, target_id string, binding_keys variant, transaction_type string, status string, request variant, response variant, error string, execution_timestamp timestamp) cluster by (batch_id, run_id, binding_id, source_id, target_id, status, transaction_type);`
    promises.push(snowflakeQuery({ query: ingestionDetailsCreationQuery, runOn: 'account' }))

    // Schema Table

    const promiseResponse = await Promise.all(promises)
    console.log('Response For Initial Creation: ', promiseResponse)

    // Do not wait for the following to complete as they will error out doing so
    // Setup Query History Execution
    executeQueryHistoryBackfill(lastHourOfDataTimestamp) // Not using Promises As It Will Timeout

    // Initialize First Time Setup of Schemas, Schema Changes, and Table Freshness Refreshes
    cloudFunction({ functionName: 'snowflakeSchemaFirstTimeLoad', payload: { accountSettings } })

    // Initialize First Time Setup of Snowflake Storage Data
    cloudFunction({ functionName: 'snowflakeTableStorageFirstTimeLoad' })

    // Initialize First Time Snowflake Tasks Setup
    cloudFunction({ functionName: 'snowflakeTasksFirstTimeLoad' })

    console.log('Finished First Time Loads...')

    return promiseResponse
  } catch (err) {
    const errorMessage = `Failed Setting Up Initial Data Loads For Account Setup: ${err}`
    console.log(errorMessage)
    throw errorMessage
  }
}

// eslint-disable-next-line import/prefer-default-export
export { initiateFirstTimeLoads }
