function toKebabCase(string) {
  // to-kebab-case
  return string
    .split('')
    .map(letter => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`
      }

      return letter
    })
    .join('')
    .trim()
    .replace(/[_\s]+/g, '-')
}

function toTitleCase(string) {
  // To Title Case
  return toKebabCase(string)
    .split('-')
    .map(word => word.slice(0, 1).toUpperCase() + word.slice(1))
    .join(' ')
}

function toCamelCase(string) {
  // toCamelCase
  return toKebabCase(string)
    .split('-')
    .map((word, index) => {
      if (index === 0) return word

      return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join('')
}

function toSentenceCase(string) {
  // To sentence case
  const interim = toKebabCase(string).replace(/-/g, ' ')

  return interim.slice(0, 1).toUpperCase() + interim.slice(1)
}

function toSnakeCase(string) {
  // to_snake_case
  return toKebabCase(string).replace(/-/g, '_')
}

function convertKeys(rows, type) {
  if (!type) return "CovertKeys Error: No Type Was Provided! ( i.e. 'toCamelCase')"
  const convertedRows = rows.map(m => ({ ...m }))
  rows.forEach((row, i) => {
    Object.keys(row).forEach(key => {
      let newKey = null
      if (type === 'toCamelCase') newKey = toCamelCase(key)
      if (type === 'toKebabCase') newKey = toKebabCase(key)
      if (type === 'toSnakeCase') newKey = toSnakeCase(key)
      delete convertedRows[i][key]
      convertedRows[i][newKey] = rows[i][key]
    })
  })

  return convertedRows
}

// eslint-disable-next-line object-curly-newline
export { toKebabCase, toTitleCase, toCamelCase, toSentenceCase, toSnakeCase, convertKeys }
