<!-- Create New App Password -->
<template>
  <div>
    <v-card-actions>
      <v-row
        justify="space-around"
      >
        <v-col
          sm="12"
        >
          <v-alert
            border="left"
            colored-border
            text
            color="primary"
          >
            <strong>Let's get Snowflake's OAuth Authentication Setup for {{ snowflakeAccountId }}</strong>
          </v-alert>
          <p>Setting up Snowflake's OAuth Authentication is easy and only requires a couple steps. Just fill in the prompts and we will provide the scripts needed to create a Snowflake integration.</p>
          <p>Please note that creating a Snowflake Integration requires ACCOUNTADMIN privileges or a role with the global CREATE INTEGRATION privilege.</p>
          <p class="caption">
            More Information Regarding Snowflake OAuth for Custom Clients to get auth tokens: <a
              href="https://docs.snowflake.com/en/user-guide/oauth-custom.html"
              target="_blank"
            >Snowflake Docs</a>
          </p>
        </v-col>
        <v-col sm="12">
          <v-stepper
            v-model="activeStep"
            class="custom-header"
          >
            <!-- Header -->
            <v-stepper-header>
              <!-- Header: Step 1 -->
              <v-stepper-step
                :complete="activeStep > 1"
                step="1"
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3">01</span>
                  <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">OAuth Details</span>
                    <span class="text--secondary text-xs">Setup Integration Details</span>
                  </div>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 2 -->
              <v-stepper-step
                :complete="activeStep > 2"
                step="2"
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3">02</span>
                  <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">Execute Script</span>
                    <span class="text--secondary text-xs">Execute Script in Snowflake</span>
                  </div>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 3 -->
              <v-stepper-step step="3">
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3">03</span>
                  <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">Verification</span>
                    <span class="text--secondary text-xs">Verify Integration</span>
                  </div>
                </div>
              </v-stepper-step>
            </v-stepper-header>

            <!-- Stepper Items -->
            <v-stepper-items>
              <!-- Stepper Content: Step 1 -->
              <v-stepper-content step="1">
                <v-card
                  outlined
                  class="mb-8 pa-10"
                >
                  <v-row>
                    <v-col
                      sm="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="oauthIntegrationName"
                        label="OAuth Integration Name"
                        outlined
                        required
                        hint="Integration name can be anything you choose"
                        persistent-hint
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      sm="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="tokenTTL"
                        label="Token Expiration in MINUTES"
                        outlined
                        required
                        hint="When the token expires the user will be logged out"
                        persistent-hint
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
                <div class="d-flex justify-end">
                  <v-btn
                    :disabled="!snowflakeAccountId || !oauthIntegrationName || !tokenTTL"
                    color="primary"
                    @click="activeStep = 2"
                  >
                    Next
                  </v-btn>
                </div>
              </v-stepper-content>

              <!-- Stepper Content: Step 2 -->
              <v-stepper-content step="2">
                <v-card
                  outlined
                  class="mb-8 pa-5"
                >
                  <h5>Execute the Following Script in Snowflake</h5>
                  <pre>
                      <code>
                        create or replace security integration {{ oauthIntegrationName }}
                          type = oauth
                          enabled = true
                          oauth_client = custom
                          oauth_client_type = 'CONFIDENTIAL'
                          oauth_redirect_uri = '{{ serverConfig.snowflakeOauthRedirectURI }}'
                          oauth_issue_refresh_tokens = true
                          oauth_refresh_token_validity = {{ tokenTTL * 60 }};
                      </code>
                    </pre>
                  <h5>After Execution is Successful: Click Next</h5>
                </v-card>

                <div class="d-flex justify-space-between">
                  <v-btn
                    outlined
                    @click="activeStep = 1"
                  >
                    Previous
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="activeStep = 3"
                  >
                    Next
                  </v-btn>
                </div>
              </v-stepper-content>

              <!-- Stepper Content: Step 3 -->
              <v-stepper-content step="3">
                <v-card
                  outlined
                  class="mb-8 pa-5"
                >
                  <h3>We will now need to retrieve the Integration Client and Client Secret</h3>
                  <br />
                  <h5>Execute the Following 2 Queries together in Snowflake:</h5>
                  <pre>
                      <code>
                        select system$show_oauth_client_secrets('{{ oauthIntegrationName.toUpperCase() }}') AS CLIENT;
                        select parse_json(CLIENT):OAUTH_CLIENT_ID::string AS CLIENT_ID
                          , parse_json(CLIENT):OAUTH_CLIENT_SECRET::string AS CLIENT_SECRET
                        from table(result_scan(last_query_id()));
                      </code>
                    </pre>
                  <h5>Copy and paste the CLIENT_ID and CLIENT_SECRET below:</h5>
                  <v-row class="mt-5">
                    <v-col
                      md="5"
                      sm="12"
                    >
                      <v-text-field
                        v-model="clientId"
                        label="Client ID"
                        outlined
                        required
                        persistent-hint
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      md="5"
                      sm="12"
                    >
                      <v-text-field
                        v-model="clientSecret"
                        label="Client Secret"
                        outlined
                        required
                        persistent-hint
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card>

                <div class="d-flex justify-space-between">
                  <v-btn
                    outlined
                    @click="activeStep = 2"
                  >
                    Previous
                  </v-btn>
                  <v-btn
                    :disabled="!clientId || !clientSecret"
                    color="primary"
                    class="me-2"
                    @click="showVerificationDialog = true"
                  >
                    Verify Integration
                  </v-btn>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-card-actions>

    <v-btn
      v-if="activeStep === 3 && showSuccessfulVerificationButton"
      color="success"
      block
      dark
      large
      class="mb-10"
      :loading="successLoading"
      @click="verificationSuccessful"
    >
      CLICK HERE IF VERIFICATION WAS SUCCESSFUL
    </v-btn>

    <!-- <v-alert
      v-if="passwordNotMatchedError"
      type="error"
      text
    >
      The Passwords Do Not Match. Please Try Again.
    </v-alert> -->
    <!-- Integration Verification Dialog Window -->
    <template>
      <v-dialog
        v-model="showVerificationDialog"
        persistent
        width="500"
      >
        <v-card>
          <v-card-title class="headline">
            Snowflake OAuth Verification
          </v-card-title>
          <v-card-text>Click "VERIFY" button and a new window should open. If it directs to your Snowflake login page then your integration is successful. If not, then retry the steps or please contact us for assistance.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="info"

              @click="oauthVerification"
            >
              Verify
            </v-btn>
            <v-btn
              color="success"
              @click="showVerificationDialog = false; showSuccessfulVerificationButton = true"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { functions, httpsCallable } from '@/firebaseApp'
import { useRouter } from '@core/utils'
import { passwordValidator, required } from '@core/utils/validation'
import serverConfig from '@serverConfig'
import { computed, ref } from '@vue/composition-api'

export default {
  setup() {
    const { router } = useRouter()

    // Setting New App Password
    const oauthIntegrationName = ref('SF_OAUTH_INTEGRATION')
    const tokenTTL = ref(720)
    const clientId = ref(null)
    const clientSecret = ref(null)
    const activeStep = ref(1)
    const showVerificationDialog = ref(false)
    const showSuccessfulVerificationButton = ref(false)
    const successLoading = ref(false)

    const snowflakeAccountId = computed(() => localStorage.getItem('snowflakeAccountId'))

    const oauthVerification = () => {
      console.log('Verifying Snowflake OAuth Integration...')

      // Open Browser for Authentication
      const params = {
        client_id: encodeURIComponent(clientId.value),
        response_type: 'code',
        redirect_uri: encodeURIComponent(serverConfig.snowflakeOauthRedirectURI),
      }

      // Snowflake Account: bm60497.us-central1.gcp lendio.us-east-1
      const url = `https://${snowflakeAccountId.value}.snowflakecomputing.com/oauth/authorize?client_id=${params.client_id}&response_type=code&redirect_uri=${params.redirect_uri}`
      window.open(url, '_blank')
    }

    const verificationSuccessful = async () => {
      successLoading.value = true
      const writeSnowflakeOauthCredentials = httpsCallable(functions, 'writeSnowflakeOauthCredentials')
      try {
        await writeSnowflakeOauthCredentials({
          snowflakeAccountId: snowflakeAccountId.value,
          clientId: clientId.value,
          clientSecret: clientSecret.value,
        })
        successLoading.value = false
        router.push('/')
      } catch (err) {
        console.log(err)
        successLoading.value = false
        throw err
      }
    }

    return {
      successLoading,
      verificationSuccessful,
      showSuccessfulVerificationButton,
      showVerificationDialog,
      activeStep,
      clientId,
      clientSecret,
      serverConfig,
      snowflakeAccountId,
      oauthIntegrationName,
      oauthVerification,
      passwordValidator,
      required,
      tokenTTL,
    }
  },
}
</script>

<style lang="scss" scoped>
pre code {
  background-color: #000;
  border: 1px solid #000;
  display: block;
  padding: 20px;
}
</style>
