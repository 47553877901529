<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <v-img
      class="misc-tree"
      src="@/assets/images/misc/tree-4.png"
    ></v-img>

    <div class="page-title text-center px-4">
      <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2">{{ titleMessages[Math.floor(Math.random() * titleMessages.length)] }}</span>
      </h2>
      <p class="text-sm">
        Building your Snowflake Automation Interface ...
      </p>

      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="300"
          :src="isDark ? require('@/assets/images/misc/AnimatedGearsSmall_DarkTheme.gif') : require('@/assets/images/misc/AnimatedGearsSmall.gif')"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,
      titleMessages: ['Getting Everything Ready!', 'Crunching the Data!'],
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
